import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationRounded({count, onClickCount}) {
const handlePageChange = (event, page) => {
    onClickCount(page);
};
  return (
    <Stack alignItems="center" sx={{ marginTop: '5vh', marginBottom: '100vh', width: '100%' }}  spacing={4}>
      <Pagination count={count}  onChange={handlePageChange}   sx={{ width: '100%' }}  variant="outlined" shape="rounded" />
    </Stack>
  );
}