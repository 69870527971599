import React, { useState, useEffect } from "react";
import Avatar from "@atlaskit/avatar";
import Comment, { CommentAuthor, CommentTime } from "@atlaskit/comment";
import Button from "@atlaskit/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useHistory } from "react-router-dom";
import PageHeader from "../common/pageHeader/PageHeader";
import BlogData from "../Blog.json";
import CommentSection from "./CommentSection";
import { apiService, base_image_url } from "../common/apiService.js";
import Modal from "../blog/Model.jsx";
import Loader from "../common/Loader.jsx";
import { IoMdTimer } from "react-icons/io";

const BlogSection = () => {
  const basePath = base_image_url;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectBlogList = location.state?.blogList;
  const redirectHashTagList = location.state?.hashTagList;
  const redirectCategoryList = location.state?.categoryList;
  const blogId = location.state?.blogId || queryParams.get('blogId');;

  const [blogData, setBlogData] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [hashTagList, setHashTagList] = useState([]);
  const [comments, setComments] = useState([]);
  const [basepath, setBasepath] = useState("");
  const [newslettersList, setNewslettersList] = useState([]);
  const [commentBlogId, setCommentBlogId] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [likes, setLikes] = useState(0);

  //set file Details
  const handleOpenModal = async (fileKey, filename, e) => {
    if (!localStorage.getItem("subscriber_details")) {
      setCurrentFileUrl(fileKey);
      setFileName(filename);
      setIsModalOpen(true);
    } else {
      const url = base_image_url + fileKey;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${filename}_newsletter.pdf`;
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(link.href);
        } else {
          toast.error("Failed to download file.");
        }
      } catch (error) {
        toast.error("Error while downloading the file.");
      }
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  let pageData = BlogData?.page_data ? BlogData?.page_data : {};

  const getDate = (publishDate, createdDate) => {
    const dateString = publishDate ? publishDate : createdDate;
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const getCategoryLsit = async () => {
    setDataLoaded(false);
    try {
      const response = await apiService({
        path: "/counts",
        method: "GET",
      });
      if (response.status === 200) {
        const responseData = await response.json();
        setCategoryList(responseData?.data?.blogCategoryCount);
        setHashTagList(responseData?.data?.LatesthastTags);
      }
    } catch (error) {
      toast("Something went wrong, try later");
      console.error("Error:", error);
    } finally {
      setDataLoaded(true);
    }
  };

  const searchBlogs = async () => {
    setDataLoaded(false);
    if (searchString.length < 3) {
      toast("Please enter a word more than 3 letters");
      return false;
    }
    try {
      const response = await apiService({
        path: "/search?searchString=" + searchString,
        method: "GET",
      });
      if (response.status === 200) {
        const responseData = await response.json();
        setSearchList(responseData.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setDataLoaded(true);
    }
  };

  const fetchData = async () => {
    setDataLoaded(false);
    try {
      let params = "?pageSize=5&currentPage=1&pagename=All";
      const response = await apiService({
        path: "/blog" + params,
        method: "GET",
      });
      if (!response.ok) {
        toast("Something went wrong, try later");
        throw new Error("Network response was not ok");
      }
      if (response.status === 200) {
        const result = await response.json();
        setBlogList(result?.data?.paginatedData);
        if (result.data) {
          setBasepath(result?.data?.base_path);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoaded(true);
    }
  };

  //truncate title of latest blog
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  //get single blog data
  const callingBlogDetails = async (blog_id) => {
    setDataLoaded(false);

    if (!blog_id) {
      toast("Blog ID is missing.");
      setDataLoaded(true);
      return;
    }

    try {
      const response = await apiService({
        path: `/blog/${blog_id}`,
        method: "GET",
      });

      if (response.status === 200) {
        const responseData = await response.json();
        console.log("responseData=", responseData);

        if (responseData.data) {
          setBlogData(responseData.data);

          setComments(responseData.data.comments || []);
          if (responseData.data.blog) {
            setCommentBlogId(responseData.data.blog._id || null);
            setLikes(
              responseData.data.blog.likes
                ? responseData.data.blog.likes.like_count
                : 0
            );
          } else {
            setCommentBlogId(null);
            setLikes(0);
          }
        } else {
          setBlogData(null);
          setComments([]);
          setCommentBlogId(null);
          setLikes(0);
        }
      } else {
        toast("Failed to fetch blog details.");
      }
    } catch (error) {
      toast("Something went wrong");
      console.error("Error:", error);
    } finally {
      setDataLoaded(true);
    }
  };

  //get news letter list
  const newletterList = async () => {
    try {
      const response = await apiService({
        path: "/newletterlist?pageSize=6",
        method: "GET",
      });
      if (response.status === 200) {
        const responseData = await response.json();
        setNewslettersList(responseData?.data?.paginatedData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const openFeatureBlog = (id, e) => {
    e.preventDefault();
    callingBlogDetails(id);
  };

  useEffect(() => {
    newletterList();
    if (blogId) {
      callingBlogDetails(blogId);
    } else {
      toast("Something went wrong, try later");
    }
    if (!redirectBlogList || !redirectBlogList.length > 0) fetchData();
    else setBlogList(redirectBlogList);
    if (!redirectHashTagList || !redirectHashTagList.length > 0)
      getCategoryLsit();
    else setHashTagList(redirectHashTagList);
    if (!redirectCategoryList || !redirectCategoryList.length > 0)
      getCategoryLsit();
    else setCategoryList(redirectCategoryList);
  }, []);

  const handleChnage = (e) => {
    setSearchString(e.target.value);
  };

  const refreshListView = (searchString, type, event) => {
    let params = "";
    if (type === "category") params = "&category=" + searchString;
    if (type === "taglist")
      params = params + "&tag_list=" + encodeURIComponent(searchString);
    history.push("/blog", { params: params });
  };

  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && (
        <PageHeader
          backgroundImageUrl={pageData?.backgroundImageUrl}
          title={pageData?.page_title}
          description={pageData?.page_discription}
        />
      )}
      <section className="bg-white py-5 px-5 md:py-8 lg:px-10">
        <div className="container mx-auto">
          {dataLoaded && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Blog Content */}
              <div className="lg:col-span-2">
                <div className="entry-details relative pb-1 text-[#687693]">
                  <h1
                    className="text-[#262B3E] font-bold text-lg"
                    style={{
                      fontSize: "2.2em",
                      paddingTop: 30,
                      paddingBottom: 30,
                    }}
                  >
                    {blogData?.blog?.blog_header}
                  </h1>

                  {/* Author Details */}
                  <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] my-4">
                    {/* Avatar Section */}
                    <a className="overflow-hidden rounded-full w-16 h-16 flex-shrink-0 border-2 border-gray-300 hover:border-[#c9b38c] transition duration-300">
                      <img
                        src={
                          basePath +
                          (blogData?.blog?.owner_id || "/default-avatar.png")
                        }
                        alt={blogData?.blog?.users || "author avatar"}
                        className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-105"
                      />
                    </a>

                    {/* Author Information Section */}
                    <div className="flex flex-col justify-center">
                      {/* <Comment type="author" className="font-extralight" /> */}
                      <h6 className="font-bold text-[#c9b38c]">author</h6>

                      {/* Author Name */}
                      <h4 className="text-base font-semibold text-gray-800">
                        <span className="text-[#687693] hover:text-[#c9b38c] transition-colors duration-200 ease-in-out hover:underline">
                          {blogData?.blog?.users || "Unknown Author"}
                        </span>
                      </h4>

                      {/* Date Section */}
                      <span className="text-gray-500 text-xs mt-1 flex items-center gap-1">
                        <IoMdTimer className="w-4 h-4" />
                        <span>
                          {getDate(blogData?.blog?.created_date, null) ||
                            "Unknown Date"}
                        </span>
                      </span>
                    </div>
                  </div>

                  {/* Blog Details */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blogData?.blog?.blog_details,
                    }}
                  />

                  {/* Comments Section */}
                  <CommentSection
                    comments={comments}
                    blogId={commentBlogId}
                    callingBlogDetails={callingBlogDetails}
                    likesCount={likes}
                  />
                </div>
              </div>

              {/* Sidebar */}
              <div className="lg:col-span-1">
                <div className="space-y-8">
                  {/* Search */}
                  <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                    <h3 className="text-lg font-bold mb-4">Search</h3>
                    <div className="flex w-full border-2 h-12 border-gray-300 rounded">
                      <input
                        type="text"
                        value={searchString}
                        onChange={handleChnage}
                        className="w-full bg-white focus:outline-none rounded-l px-3"
                        placeholder="Search"
                      />
                      <button
                        onClick={searchBlogs}
                        className="bg-[#c9b38c] text-white px-6 text-lg font-semibold py-2 rounded-r"
                      >
                        Go
                      </button>
                    </div>

                    {/* Search Results */}
                    {searchList.length > 0 && (
                      <ul className="space-y-2 mt-4">
                        {searchList.map((blog, index) => (
                          <li key={index}>
                            <a
                              onClick={openFeatureBlog.bind(null, blog?._id)}
                              className="text-[#687693] hover:text-[#c9b38c] transition duration-100"
                            >
                              {truncateText(blog?.blog_header, 10)}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  {/* Newsletter */}
                  <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                    <h3 className="text-lg font-bold mb-4">Newsletter</h3>
                    <ul className="space-y-2">
                      {newslettersList.length > 0 &&
                        newslettersList.map((newsletter, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between"
                          >
                            <div className="flex space-x-2">
                              <h4 className="text-sm font-bold">
                                {newsletter.news_letter_month ||
                                  getDate(newsletter.created_date)}
                              </h4>
                              <span className="text-gray-600 text-xs flex items-center">
                                <i className="ti-timer"></i>
                              </span>
                            </div>
                            <button
                              onClick={handleOpenModal.bind(
                                null,
                                newsletter?.newsletter_c_key,
                                newsletter?.news_letter_month
                              )}
                              className="flex items-center h-10 bg-[#AB9163] text-white px-4 py-2 shadow-md hover:shadow-none transition-all rounded"
                            >
                              Download
                            </button>
                          </div>
                        ))}
                    </ul>
                  </div>

                  {/* Categories */}
                  <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                    <h3 className="text-lg font-bold mb-4">Categories</h3>
                    <ul className="space-y-2">
                      {categoryList.length > 0 &&
                        categoryList.map((category, index) => (
                          <div key={index} className="hover:bg-gray-100 cursor-pointer px-2 py-2 rounded-md transition-all">
                            <a
                              onClick={refreshListView.bind(
                                null,
                                category?._id[0],
                                "category"
                              )}
                              className="text-[#687693] hover:text-[#c9b38c] transition-all duration-100 "
                            >
                              {category?._id[0]}
                              <span> ({category?.count})</span>
                            </a>
                          </div>
                        ))}
                    </ul>
                  </div>

                  {/* Recent Posts */}
                  <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] ">
                    <h3 className="text-lg font-bold mb-4">Recent Posts</h3>
                    <div className="space-y-4 ">
                      {blogList?.map((data, index) => (
                        <div
                          key={index}
                          onClick={openFeatureBlog.bind(null, data?._id)}
                           >
                          <div className="flex justify-center items-center">
                            <a
                              href="#"
                              className="w-16 h-16 overflow-hidden rounded-lg flex items-center justify-center"
                            >
                              <Avatar
                                src={basePath + data?.owner?._id}
                                alt="avatar"
                              />
                            </a>
                          </div>
                          <div>
                            <h4 className="text-sm font-bold">
                              <a
                                onClick={openFeatureBlog.bind(null, data?._id)}
                                className="text-[#687693] hover:text-[#c9b38c] duration-100 hover:underline"
                              >
                                {truncateText(data?.blog_header, 5)}
                              </a>
                            </h4>
                            <span className="text-gray-600 text-xs flex items-center gap-1">
                              <IoMdTimer className="w-4 h-4" />
                              {getDate(
                                data?.published_date,
                                data?.created_date
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Tags */}
                  <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                    <h3 className="text-lg font-bold mb-4">Tags</h3>
                    <div className="flex flex-wrap gap-2">
                      {hashTagList?.map((data, index) => (
                        <a
                          key={index}
                          onClick={refreshListView.bind(null, data, "taglist")}
                          className="bg-gray-200 px-3 py-1 rounded-lg shadow-md hover:shadow-none text-gray-800 text-sm hover:text-[#c9b38c] transition duration-100"
                        >
                          {data}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <ToastContainer />
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        fileUrl={base_image_url + currentFileUrl}
        filename={fileName}
      />
    </>
  );
};

export default BlogSection;
