import React, { useState, useEffect } from "react"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Recent from "./recent/Recent"
import Team from "./team/Team"
import { apiService } from "../../components/common/apiService.js";
import Loader from "../common/Loader.jsx"

const Home = () => {
  const [homePageData, setHhomePageData] = useState({});
  const [sliceData, setSliceData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false); // New state for data loading
  const handleSubmit = async () => {
    try {
      const response = await apiService({
        path: "/page/home_page",
        method: "GET"
      });
      const responseData = await response.json();
      setHhomePageData(responseData.data);
      let sliceData = responseData?.data?.page_data?.slide_data;
      sliceData.map((item, index) => (
        item["image"] = responseData?.data["base_path"] + item["image"]
      ))
      setSliceData(sliceData);
      setDataLoaded(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    handleSubmit();
  }, []);



  console.log(homePageData.page_data, "recet")
  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && (
        <>
          <Hero slides={sliceData} />
          <div className="bg-white py-12 container mx-auto">
            <Recent serverUrl={homePageData.base_path} recent={homePageData.page_data.recent_section} />
            <Featured feature_data={homePageData.page_data.feature_data} />
            <Team blog_section={homePageData.page_data.blog_section} />
          </div>
        </>
      )}
    </>
  );
}

export default Home
