import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import { Dialog } from "@material-tailwind/react";
// import { IoIosCloseCircle } from "react-icons/io";
// import { RxLinkedinLogo } from "react-icons/rx";

const MeetOurExpertsSection = ({ teamsData, basePath,backgroundImageUrl }) => {
  let data = teamsData.employees ? teamsData.employees : [];
  data.sort((a, b) => a.order - b.order);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null); // Track selected item

  useEffect(() => {
    // Check if the URL contains the hash #MeetOurExpertsSection
    if (window.location.hash === "#MeetOurExpertsSection") {
      const element = document.getElementById("MeetOurExpertsSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  },[]);

  // const sliderSettings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         arrows: true,
  //       },
  //     },
  //     {
  //       breakpoint: 640,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         arrows: true,
  //       },
  //     },
  //   ],
  //   prevArrow: (
  //     <button
  //       className="slick-prev sm:hidden"
  //       aria-label="Previous"
  //       style={{ left: "-60px", zIndex: 1 }}
  //     >
  //       <i className="fas fa-chevron-left"></i>
  //     </button>
  //   ),
  //   nextArrow: (
  //     <button
  //       className="slick-next sm:hidden"
  //       aria-label="Next"
  //       style={{ right: "-40px", zIndex: 1 }}
  //     >
  //       <i className="fas fa-chevron-right "></i>
  //     </button>
  //   ),
  // };

  // const openModal = (item) => {
  //   setSelectedItem(item);
  //   setIsModalOpen(true);
  // };

  return (
    <section id="MeetOurExpertsSection" className=" py-16 md:py-12 lg:py-12 xl:py-12 ">
      {/* <div className="container mx-auto">
        <div className="text-center mb-8">
          <span className="text-sm text-[#c9b38c] font-bold">
            QUALIFIED ATTORNEYS
          </span>
          <h2 className="text-2xl md:text-4xl lg:text-4xl font-normal text-gray-900 mt-2">
            {teamsData.title}
          </h2>
          <p className="text-lg text-gray-700 mt-4">{teamsData.description}</p>
        </div>

        <div className="slider-container px-10 lg:px-5 ">
          <Slider {...sliderSettings}>
            {data.map((item, index) => (
              <div
                key={index}
                className="px-2 py-2 pb-6"
                onClick={() => openModal(item)}
              >
                <div className="bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4">
                  <img
                    src={basePath + item.image}
                    alt={item.first_name}
                    className="w-full h-64 object-cover object-top-100px rounded-lg mb-4 cursor-pointer"
                  />
                  <div className="text-center">
                    <h3 className="text-lg font-semibold text-gray-900">
                      {item.first_name} {item.last_name}
                    </h3>
                    <p className="text-sm text-gray-700">{item.designation}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {selectedItem && (
          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div className="bg-gray-200 dark:bg-gray-800 flex flex-wrap items-center w-full overflow-hidden">
              <div className="container bg-white shadow-lg transform duration-200 easy-in-out">
                <div className="h-32 overflow-hidden">
                  <img
                    className="w-full object-cover object-top-100px"
                    // src={basePath + selectedItem.image}
                    src='https://images.unsplash.com/photo-1589829545856-d10d557cf95f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")'
                    alt={selectedItem.first_name}
                  />
                </div>
                <div className="flex justify-center px-5 -mt-12">
                  <img
                    className="h-36 w-36 bg-white p-2 rounded-full object-cover object-top"
                    src={basePath + selectedItem.image}
                    alt={selectedItem.first_name}
                  />
                </div>
                <div className="absolute p-1 top-0 right-0">
                  <div onClick={() => setIsModalOpen(false)}>
                    <IoIosCloseCircle className="text-4xl text-[#dfdfdf] shadow-2xl hover:text-[#fff] cursor-pointer duration-75 transition-all" />
                  </div>
                </div>
                <div className="text-center px-6">
                  <h2 className="text-gray-800 text-3xl font-bold">
                    {selectedItem.first_name} {selectedItem.last_name}
                  </h2>
                  <div className="flex justify-center items-center gap-2">
                    <p className="text-lg font-bold">{selectedItem.designation}</p>

                    {selectedItem.linkdin && (
                      <a
                        className="text-gray-400 hover:text-blue-500 flex items-center"
                        href={selectedItem.linkdin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <RxLinkedinLogo className="text-[#0077B7] hover:text-[#0063C2] text-2xl transition-all" />
                      </a>
                    )}
                  </div>

                  <p className="mt-2 text-gray-700 text-sm font-bold text-justify overflow-y-auto max-h-[16vh] transition-all">
                    {selectedItem.profile_summary}
                  </p>
                  <p className="text-gray-500 text-sm mt-2 text-justify">
                    {selectedItem.bio}
                  </p>
                </div>
                <hr className="mt-6" />
              </div>
            </div>
          </Dialog>
        )}
      </div> */}
    </section>
  );
};

export default MeetOurExpertsSection;
