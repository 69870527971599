import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import { APP_BASE_URL, callingSecureAPI } from '../components/Service/RequestService'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const isUserPrset = atob(localStorage.getItem("userLoginData"))
  let user = {};
  if (isUserPrset)
    user = JSON.parse(isUserPrset);
  const [formValues, setFormValues] = useState(user);
  const [postcount, setPostcount] = useState({});
  const basePath = APP_BASE_URL
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);

  const handleChange = (event, newValue) => {
    if (newValue === 1)
      setFormValues(user)
    if (newValue === 2) {
      setFormValues({})
      getRoleList()
    }
    setValue(newValue);
  };

  const openEditDetails = () => {
    setFormValues(user)
    setValue(1);
  }


  //get all available role from DB
  const getRoleList = async () => {
    const role_utility_key = "pannel_jre_role_system"
    try {
      setLoading(true)
      const result = await callingSecureAPI("/utility/" + role_utility_key, "GET")
      const responseData = await result.json()
      const roleList = responseData?.data?.data_source || []
      setRoleList(roleList.filter(item => item.role_key !== "super_admin"));
      console.log(roleList)
    } catch (error) {
      setLoading(false)
    }
    finally {
      setLoading(false)
    }

  }

  const [imageList, setImageList] = useState(null);
  //update profile details
  const updateUserDetails = async () => {
    if (!validateForm()) {
      return;
    }
    const updateUserBody = {
      _id: formValues._id,
      address: formValues.address,
      city: formValues.city,
      country: formValues.country,
      zipcode: formValues.zipcode,
      permanent_address: formValues.permanent_address,
      p_city: formValues.p_city,
      p_country: formValues.p_country,
      p_zipcode: formValues.p_zipcode,
      whatsapp_number: formValues.whatsapp_number,
      description: formValues.description,
      last_updated_by: user?.data?.user_details._id
    };
    try {
      const response = await callingSecureAPI('/user', 'PUT', {}, updateUserBody);
      if (response.status === 200) {
        const result = await response.json();
        toast("Your Profile Details Saved Successfully!");
        //const newUserDetails = (JSON.parse(atob(localStorage.getItem("userLoginData"))))
        localStorage.setItem('userLoginData', btoa(JSON.stringify(result?.data || {})));
        setFormValues(result?.data);
        setValue(0)
      }
    } catch (error) {
      toast("Something went wrong, please try again later");
      console.error('Error:', error);
    }
  }


  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "file-upload") {
      setFormValues({
        ...formValues,
        imagePath: files[0],
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!formValues.username || formValues.username.length < 3) {
      toast("Username is a required field or enter a valid username");
      return false;
    }
    else if (!formValues.email) {
      toast("Email address is a required field");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formValues.email)) {
      toast("Please enter a valid email address");
      return false;
    }

    else if (!formValues.first_name || formValues.first_name.length < 3) {
      toast("First name is a required field or enter a valid first name");
      return false;
    }

    else if (!formValues.last_name || formValues.last_name.length < 3) {
      toast("Last name is a required field or enter a valid last name");
      return false;
    }

    else if (!formValues.phone_number) {
      toast("Phone number is a required field");
      return false;
    }

    else if (!formValues.position) {
      toast("Position is a required field");
      return false;
    }
    else if (!formValues.address || formValues.address.length < 3) {
      toast("Address is a required field or enter a valid address");
      return false;
    }

    else if (!formValues.city || formValues.city.length < 3) {
      toast("City is a required field or enter a valid city");
      return false;
    }
    else if (!formValues.country || formValues.country.length < 3) {
      toast("Country is a required field or enter a valid country");
      return false;
    }

    else if (!formValues.zipcode || formValues.zipcode.length < 6) {
      toast("Zipcode is a required field or enter a valid zipcode");
      return false;
    }

    else if (!formValues.permanent_address || formValues.permanent_address.length < 3) {
      toast("Permanent address is a required field or enter a valid permanent address");
      return false;
    }
    else if (!formValues.p_country || formValues.p_country.length < 3) {
      toast("Permanent country is a required field or enter a valid country");
      return false;
    }

    else if (!formValues.p_city || formValues.p_city.length < 3) {
      toast("Permanent city is a required field or enter a valid city");
      return false;
    }

    else if (!formValues.p_zipcode || formValues.p_zipcode.length < 6) {
      toast("Permanent zipcode is a required field or enter a valid zipcode");
      return false;
    }

    else if (!formValues.description || formValues.description.length < 3) {
      toast("About me is a required field or enter a valid description");
      return false;
    }

    return true;
  };

  const imageChangeHandle = async (event) => {
    setImageList(event.target.files[0])
  };
  //upload profile image
  const uploadImage = async () => {
    let owner_id = "";
    if (user) {
      owner_id = user?._id
    }
    if (owner_id && owner_id.length > 0) {
      if (!imageList)
        toast("No Images Selected, Please Select Atleast One Image")

      //create a form data body
      const formData = new FormData();
      formData.append("files", imageList);
      formData.append("c_key", owner_id);

      try {
        const response = await callingSecureAPI('/fileimage', 'POST', {}, formData);
        if (response.status === 200) {
          toast("Images Uploaded SuccessFully");
          setImageList('')
          setValue(0)
        }
      } catch (error) {
        toast("Something went wrong, try later");
        console.error("Error:", error);
      }
    }
  }

  //add user in the system
  const addNewUser = async () => {
    if (!validateForm()) {
      return;
    }
    const postionData = roleList.filter(item => item.role_key == formValues["position"])[0] || {}

    if (!postionData.description || !postionData.permissions) {
      toast("Position is a required field")
      return
    }

    let body = formValues;
    body["created_by"] = user?._id
    body["last_updated_by"] = user?._id
    body["position_desc"] = postionData.description
    body["permissions"] = postionData.permissions
    body["name"] = (formValues.first_name ? formValues.first_name : "") + " " + (formValues.last_name ? formValues.last_name : "")
    try {
      const response = await callingSecureAPI('/register', 'POST', {}, body);
      if (response.status === 200) {
        const result = await response.json();
        toast(result?.message);
        if (result?.message === "User Register Successfully")
          handleChange(null, 2)
        setValue(0)
      }
    } catch (error) {
      toast("Something went wrong, please try again later");
      console.error('Error:', error);
    }
  }
  //fetch Data from DB
  const fetchData = async () => {
    let owner_id = "";
    const user = JSON.parse(atob(localStorage.getItem("userLoginData")));
    console.log("here", user)
    if (user) {
      owner_id = user?._id
    }
    try {
      const response = await callingSecureAPI('/blogs/counts?owner_id=' + owner_id, 'GET');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (response.status === 200) {
        const result = await response.json();
        setPostcount(result?.data);
      }
    } catch (error) {
      toast("Ssomething wrong, try later");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="My Details"
            {...a11yProps(0)}
            sx={{ color: '#A69FD6', '&.Mui-selected': { color: '#6558D5' } }}
          />
          <Tab
            label="Settings"
            {...a11yProps(1)}
            sx={{ color: '#A69FD6', '&.Mui-selected': { color: '#6558D5' } }}
          />
          {
            user?.position == "super_admin" &&
            <Tab
              label="Add User"
              {...a11yProps(2)}
              sx={{ color: '#A69FD6', '&.Mui-selected': { color: '#6558D5' } }}
            />
          }
          
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <section className="relative py-80 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words text-[#6558d3] w-full mb-6 -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative w-32 h-32">
                      <img
                        alt="Profile"
                        src={basePath + (user?._id ? user?._id : "no_image")}
                        className="absolute top-0 left-0 w-full h-full shadow-xl rounded-full object-cover border-none"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-lightBlue-500 active:bg-lightBlue-600 uppercase hover:bg-white text-[#6558d3] border border-[#6558d3] font-bold hover:shadow-md text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={openEditDetails}
                      >
                        Edit Details
                      </button>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          {postcount?.totalPosts}
                        </span>
                        <span className="text-sm text-blueGray-400">Blogs</span>
                      </div>
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          {postcount?.totalComments}
                        </span>
                        <span className="text-sm text-blueGray-400">Comments</span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          {postcount?.totalLikes}
                        </span>
                        <span className="text-sm text-blueGray-400">Likes</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700">
                    {user?.name}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                    {user?.address}
                  </div>
                  <div className="mb-2 text-blueGray-600 mt-10">
                    <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                    {user?.position_desc}
                  </div>
                  <div className="mb-2 text-blueGray-600">
                    <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                    {user?.graduation} : {user?.university}
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                        {user?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CustomTabPanel>



      {/* Edit Details Part */}
      <CustomTabPanel value={value} index={1}>
        <div>
          <div className="flex flex-wrap ">
            <div className="w-full lg:w-8/12 px-4">
              <div className="relative  flex flex-col min-w-0 break-words w-full mb-6  bg-blueGray-100 border-0">
                <div className="rounded-t  mb-0 px-6 py-6">
                  <div className="text-center flex justify-between">
                    <h6 className="text-blueGray-700 text-xl font-bold">
                      My account
                    </h6>
                    <button
                      className="  text-[#6558d3]   border-2 hover:bg-white border-[#6558d3] active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={updateUserDetails}
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form>
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      User Information
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.username}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.email}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.first_name}
                            name="first_name"
                            onChange={handleInputChange}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.last_name}
                            name="last_name"
                            onChange={handleInputChange}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.phone_number}
                            name="phone_number"
                            onChange={handleInputChange}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Whatsapp Number
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.whatsapp_number}
                            name="whatsapp_number"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="position"
                          >
                            Position
                          </label>
                          <input
                            type="text"
                            disabled
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.position_desc || formValues?.position}
                            name="position"
                            onChange={handleInputChange}
                          />

                        </div>
                      </div>
                    </div>
                    <hr className="mt-6 border-b-1 border-blueGray-300" />

                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      Contact Information
                    </h6>

                    {/* Current Address */}
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Current Address
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.address}
                            name="address"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.city}
                            name="city"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Country
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.country}
                            name="country"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Postal Code
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.zipcode}
                            name="zipcode"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Permanent Address Address */}
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Permanent Address
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.permanent_address}
                            name="permanent_address"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.p_city}
                            name="p_city"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Country
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.p_country}
                            name="p_country"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Postal Code
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.p_zipcode}
                            name="p_zipcode"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>


                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      My bio

                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >  About Me

                          </label>
                          <textarea
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue={formValues?.description}
                            name="description"
                            onChange={handleInputChange}
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-4/12 rounded-md bg-[#a69fd6] text-white px-4">
              <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  mt-16">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    <div className=" px-4 flex justify-center">
                      <div className="relative w-44 h-44">
                        <img
                          alt="..."
                          src={basePath + (user?._id ? user?._id : "no_image")}
                          className="shadow-xl h-full rounded-lg object-cover  align-middle border-none absolute"
                        />
                      </div>
                    </div>
                    <div className="w-full px-10 " >
                      <div className="flex flex-col justify-center">
                        <input type="file" accept="image/*"
                          onChange={imageChangeHandle}
                          id="files"
                          className="my-3 border-2 border-white w-[100%]"
                          name="files"></input>
                        <button
                          className=" text-[#6558d3]  flex justify-center o object-center  border-2 hover:bg-white border-[#6558d3] active:bg-lightBlue-600 font-bold uppercase  hover:shadow-md p-2 focus:outline-none  ease-linear transition-all duration-150"
                          type="button"
                          onClick={uploadImage}
                        >
                          Change Profile
                        </button>
                      </div>
                    </div>

                    <div className="w-full px-4 text-center mt-20">
                      <div className="flex justify-center py-4 lg:pt-4 pt-8">
                        <div className="mr-4 p-3 text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                            {postcount?.totalPosts}
                          </span>
                          <span className="text-sm text-blueGray-400">
                            Blogs
                          </span>
                        </div>
                        <div className="mr-4 p-3 text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                            {postcount?.totalComments}
                          </span>
                          <span className="text-sm text-blueGray-400">
                            Comments
                          </span>
                        </div>
                        <div className="lg:mr-4 p-3 text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                            {postcount?.totalLikes}
                          </span>
                          <span className="text-sm text-blueGray-400">
                            Likes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-12">
                    <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                      {formValues?.name}
                    </h3>
                    <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                      <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                      {formValues?.address}
                    </div>
                    <div className="mb-2 text-blueGray-600 mt-10">
                      <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                      {formValues?.position_desc}
                    </div>
                    <div className="mb-2 text-blueGray-600">
                      <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                      {formValues?.graduation} : {formValues?.university}
                    </div>
                  </div>
                  <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                    <div className="flex flex-wrap justify-center">
                      <div className="w-full lg:w-9/12 px-4">
                        <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                          {formValues?.description}
                        </p>
                        {/* <a
                          href="#pablo"
                          className="font-normal text-lightBlue-500"
                          onClick={(e) => e.preventDefault()}
                        >
                          Show more
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomTabPanel>

      {/* Add New User */}
      {
        user?.position == "super_admin" &&
        <CustomTabPanel value={value} index={2}>
          <div>
            <div className="flex flex-wrap text-black">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-blueGray-100 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                      <h6 className="text-blueGray-700 text-xl font-bold">
                        Enter New User Details
                      </h6>
                      <button
                        className="text-[#6558d3] hover:bg-white border-2 border-[#6558d3] active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={addNewUser}
                      >
                        Add User
                      </button>
                    </div>
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                      <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        User Information
                      </h6>
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-username">
                              Username (Email)
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.username}
                              onChange={handleInputChange}
                              name="username"
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-email">
                              Email address
                            </label>
                            <input
                              type="email"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.email}
                              onChange={handleInputChange}
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                              Default Password
                            </label>
                            <input
                              type="password"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.password}
                              name="password"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-first-name">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.first_name}
                              name="first_name"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-last-name">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.last_name}
                              name="last_name"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-phone-number">
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.phone_number}
                              name="phone_number"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-whatsapp-number">
                              Whatsapp Number
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.whatsapp_number}
                              name="whatsapp_number"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-dob">
                              Date Of Birth
                            </label>
                            <input
                              type="date"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.dob}
                              name="dob"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-doj">
                              Date Of Joining
                            </label>
                            <input
                              type="date"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.doj}
                              name="doj"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-position">
                              Position
                            </label>
                            <select
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.position || ""}
                              name="position"
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                Select Position
                              </option>
                              {
                                roleList.map((role, index) => (
                                  <option key={index} value={role?.role_key}>{role?.description}</option>
                                ))
                              }

                            </select>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-6 border-b-1 border-blueGray-300" />
                      <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        Contact Information
                      </h6>
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-address">
                              Current Address
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.address}
                              name="address"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-city">
                              City
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.city}
                              name="city"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-country">
                              Country
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.country}
                              name="country"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-postal-code">
                              Postal Code
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.zipcode}
                              name="zipcode"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Permanent Address */}
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-permanent-address">
                              Permanent Address
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.permanent_address}
                              name="permanent_address"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-p-city">
                              City
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.p_city}
                              name="p_city"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-p-country">
                              Country
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.p_country}
                              name="p_country"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-p-postal-code">
                              Postal Code
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.p_zipcode}
                              name="p_zipcode"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <hr className="mt-6 border-b-1 border-blueGray-300" />
                      <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        About New User
                      </h6>
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                          <div className="relative w-full mb-3">
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-description">
                              About New User
                            </label>
                            <textarea
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={formValues?.description}
                              name="description"
                              onChange={handleInputChange}
                              rows="4"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomTabPanel>
      }


      <ToastContainer />
    </Box>
  );
}
