import React from 'react'
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { BiSend } from "react-icons/bi";
import { IoLocationSharp } from "react-icons/io5";
import { IoTimeOutline } from "react-icons/io5";
import { GoLaw } from "react-icons/go";
import { Fa1 , Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9} from "react-icons/fa6";




const IconsHandler = ({name}) => {
    return (
        <>
        { name === "IoLocationSharp" && <IoLocationSharp className="text-3xl text-[#c9b38c] mr-4" />}
        {name === "HiOutlinePhone" && <HiOutlinePhone className="text-3xl text-[#c9b38c] mr-4" />}
        {name === "IoTimeOutline" &&  <IoTimeOutline className="text-3xl text-[#c9b38c] mr-4" />}
        {name === "HiOutlineMail" &&  <HiOutlineMail className="text-3xl text-[#c9b38c] mr-4" />}
        {name === "BiSend" && <BiSend className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        
        {name === "GoLaw" && <GoLaw className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa1" && <Fa1 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa2" && <Fa2 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa3" && <Fa3 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa4" && <Fa4 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa5" && <Fa5 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa6" && <Fa6 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa7" && <Fa7 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa8" && <Fa8 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        {name === "Fa9" && <Fa9 className="text-[#fff] text-4xl bg-[#c9b38c] p-2 rounded-full" />}
        </>
    )
}
export default IconsHandler