import React, { useEffect, useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography";
import {APP_BASE_URL , callingSecureAPI} from '../components/Service/RequestService'

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'ISO\u00a0Email', minWidth: 100 },
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'message',
    label: 'Message\u00a0',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'service_id',
    label: 'Service',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

export default function ContactUs() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const callListData = useCallback(async (etcParam) => {
    setIsLoading(false)
    try {
      let params = "&currentPage=1&pageSize="+rowsPerPage
      const response = await callingSecureAPI('/contactus/list?page_c_key=contact_us_form'+ (etcParam ? etcParam : params) + params + etcParam,'GET');
      if (response.status === 200) {
        const responseData = await response.json();
        setDataList(responseData?.data?.paginatedData?.map((item, index) => (
          createData(item)
        )));
        setTotalRecords(responseData?.data?.totalCount[0]?.total)
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(true)
      toast("Something wrong, try later")
    } finally {
      setIsLoading(true)
    }
  }, []); // Empty dependency array ensures this function is only created once

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
    callListData("&currentPage=" + (newPage +1) + "&pageSize="+rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    callListData("&currentPage=1&pageSize="+event.target.value)
  };

  function createData(item) {
    const name = (item?.first_name + " " + item?.last_name);
    const email = item?.email ? item?.email : "-";
    const phone_number = item?.phone_number ? item?.phone_number : "-";
    const message = item?.message ? item?.message : "-";
    const service_id = item?.service_id ? item?.service_id : "-"
    return { name, email, phone_number, message, service_id };
  }

  console.log(dataList)
  useEffect(() => {
    callListData();
  }, [callListData]); // Including callListData as a dependency

  return (
    <Paper sx={{ width: '100%' }}>
      {isLoading &&
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
              <TableCell align="center" colSpan={7}>
                  <Typography variant="h5" component="h5" className="">
                  Contact Us Queries List View
                  </Typography>
                </TableCell>
                
              </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id + index}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { dataList
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.name + index}>
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id + index} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>}

      {page > 0 &&
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page-1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      <ToastContainer />
    </Paper>
  );
}
