import React, { useState, useEffect } from 'react'
import PageHeader from '../common/pageHeader/PageHeader'
import JoinTeam from './JoinTeam';
import { apiService } from "../common/apiService.js";
import Loader from "../common/Loader.jsx"
const Career = () => {

  const [pageData, setPageData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // New state for data loading

  //api calling
  const handleSubmit = async () => {
    try {
      const response = await apiService({
        path: "/page/career_page",
        method: "GET"
      });
      const responseData = await response.json();
      setPageData(responseData.data);
      setDataLoaded(true); // Set dataLoaded to true after fetching data
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    handleSubmit();
  }, []);


  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && <PageHeader
        backgroundImageUrl={pageData?.page_data?.backgroundImageUrl}
        title={pageData?.page_data?.page_title}
        description={pageData?.page_data?.page_discription}
      />}
      {dataLoaded && <JoinTeam data={pageData?.page_data} />}

    </>
  )
}

export default Career