import React, { useState, useEffect } from "react";
import { FaRegLightbulb } from "react-icons/fa6";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import "../price/price.css";
import { useHistory } from "react-router-dom";
import { apiService, base_image_url } from "../../../components/common/apiService.js";
import Loader from "../../common/Loader.jsx";

const Team = ({ blog_section }) => {
  const history = useHistory();
  const [blogList, setBlogList] = useState([]);
  const [basepath, setBasepath] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);

  const no_image = "no_image_found"

  const fetchData = async () => {
    try {
      setDataLoaded(true)
      let params = "?pageSize=2&currentPage=1&pagename=All";
      const response = await apiService({
        path: "/blog" + params,
        method: "GET",
      });
      if (!response.ok) {
        setDataLoaded(false)
        throw new Error("Network response was not ok");
      }
      if (response.status === 200) {
        setDataLoaded(false)
        const result = await response.json();
        setBlogList(result?.data?.paginatedData);
        if (result.data) {
          setBasepath(result?.data?.base_path);
        }
        console.log(result.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoaded(true);
    }
  };

  const getDate = (publishDate, createdDate) => {
    const dateString = publishDate ? publishDate : createdDate;
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  //open selected blog
  const openSingleBlog = (blogId) => {
    history.push("/read-blog", { blogId: blogId });
  };
  //open selected blog
  const openBlogSectionWithCategory = (category) => {
    history.push("/Blog", { params: "&category=" + category });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && (
        <>
          <div className="bg bg-[#fff] ">
            <div className=" lg:py-8 md:py-5 py-5 px-5  lg:px-10">
              <section className="bg-white  py-12">
                <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <div className="section-title-s4  mb-0 lg:flex lg:flex-row gap-3">
                    <Typography className="flex bshdow text-[#fff] w-10 h-10 sm:w-12 sm:h-12 leading-10 justify-start mb-3 items-center top-2">
                      <FaRegLightbulb className="text-3xl sm:text-4xl bg-[#c9b38c] shadow-[#f5c97e] rounded-full p-1 font-md shadow-[0 35px 60px -15px rgba(0, 0, 0, 0.3)]" />
                    </Typography>
                    <div>
                      <span className="block text-lg sm:text-xl lg:text-2xl font-medium text-gray-800 ">
                        Latest Blog
                      </span>
                      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900 mt-2">
                        Check Our Latest Tips &amp; News
                      </h2>
                      <p className="text-sm sm:text-base lg:text-lg text-gray-700 mt-2 text-justify">
                        Stay updated with the latest tips, insights, and news from
                        the legal world. Our blog is designed to keep you informed
                        about important legal issues, provide expert advice, and
                        share news relevant to our clients and community.
                      </p>
                      <Link
                        to="/blog"
                        className="inline-block py-2 sm:py-3 px-4 sm:px-6 bg-[#c9b38c] text-[#fff] font-medium rounded-none mt-6 transition duration-300"
                      >
                        More Blog Post
                      </Link>
                    </div>
                  </div>

                  {dataLoaded && (
                    <div className="col-span-2">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 flex-wrap">
                        {blogList.map((blog, key) => (
                          <Link onClick={openSingleBlog.bind(null, blog._id)}>
                            <div
                              key={key}
                              className="outline-none   w-auto h-full hover: rounded-none overflow-hidden relative hover:rounded-md hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
                            >
                              <img
                                src={
                                  base_image_url +
                                  (blog.files.length > 0
                                    ? blog.files[0]?.c_key
                                    : no_image)
                                }
                                alt=""
                                className="w-full h-64  lg:h-64 md:h-32 sm:h-32 xs:h-32 object-cover"
                              />
                              <div className="entry-details relative p-[45px 0 0 20px] border-2">
                                <div className="cat text-[#fff] text-[13px] font-bold px-3 py-2 bg-[#c9b38c] p-3 -left-1 top-[-20px] rounded-r-full absolute">
                                  {blog?.category?.map((cat, index) => (
                                    <Link
                                      key={index}
                                      onClick={openBlogSectionWithCategory.bind(
                                        null,
                                        cat
                                      )}
                                    >
                                      {cat}
                                    </Link>
                                  ))}
                                </div>
                                <div className="entry-details relative ps-8 pt-6 pb-1  text-[#687693] text-justify pr-5">
                                  <h2 className="text-[#262B3E] f font-bold text-lg">
                                    {blog?.blog_header}
                                  </h2>

                                  <Link
                                    onClick={openSingleBlog.bind(null, blog._id)}
                                  >
                                    {truncateText(blog?.description, 30)}
                                  </Link>
                                </div>
                                <div className="ps-8 pt-2 border-t-2 mt-auto pb-2 items-center text-gray-600  text-sm">
                                  <div className="p-[45px 0 0 20px]">
                                    <span>
                                      By:{" "}
                                      <Link
                                        to=""
                                        rel="author"
                                        className="text-blue-600"
                                      >
                                        {blog?.owner?.name}
                                      </Link>
                                    </span>
                                    <span className="ml-4">
                                      {getDate(
                                        blog?.published_date,
                                        blog?.created_date
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
}


export default Team;
