import React, { useEffect } from "react";
import "./footer.css";
import { Typography } from "@material-tailwind/react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { useLocation } from "react-router-dom"; // Import the useLocation hook
import img from "../../images/jrapics/jranewwhite.png";
import { Input, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { apiService } from "../apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = (props) => {
  const location = useLocation(); // Get the current location

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the location changes
  }, [location]);

  // serverurl for props
  let { data } = props;
  let lowerPart = data.loower_footer ? data.loower_footer : {};
  let privacy = data.loower_footer.policies ? data.loower_footer.policies : {};
  privacy.sort((a, b) => a.order - b.order);
  const SITEMAP = data.site_map ? data.site_map : [];
  SITEMAP.sort((a, b) => a.order - b.order);
  const currentYear = new Date().getFullYear();
  const onChange = ({ target }) => setEmail(target.value);
  const [email, setEmail] = React.useState("");

  const handleSubmit = async () => {
    let subscribeBody = {};
    if (email.includes("@")) subscribeBody["email"] = email;
    else if (!isNaN(email)) subscribeBody["phone_number"] = email;

    if (subscribeBody?.email || subscribeBody?.phone_number) {
      try {
        const responseData = await apiService({
          path: "/utility/subcriber",
          method: "POST",
          body: subscribeBody,
        });
        if (responseData.status === 200) {
          toast("You are added as newsletter subcriber, Thank You");
          setEmail("");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <section className="mx-auto w-full py-4 md:py-8 lg:py-8 xl:py-8  px-4 md:px-8 lg:px-8 xl:px-8 bg-[#1C202E]">
        <div className="mx-auto w-full max-w-7xl flex flex-col md:flex-row flex-wrap items-center justify-center md:justify-between gap-y-6 gap-x-12 text-white text-center">
          {/* Social Icons */}
          <div className="flex justify-between space-x-6">
            <a href="#!" className="hover:text-gray-400">
              <Typography className="flex text-[#fff] justify-center mb-3">
                <FaFacebook className="text-5xl center bg-[#30364E] hover:bg-[#b99c69] border-2 border-solid border-[#444A5F] duration-300 shadow-[#f5c97e] rounded-full p-3" />
              </Typography>
            </a>
            <a href="#!" className="hover:text-gray-400">
              <Typography className="flex text-[#fff] justify-center mb-3">
                <FaLinkedin className="text-5xl center bg-[#30364E] hover:bg-[#b99c69] border-2 border-solid border-[#444A5F] duration-300 shadow-[#f5c97e] rounded-full p-3" />
              </Typography>
            </a>
            <a href="#!" className="hover:text-gray-400">
              <Typography className="flex text-[#fff] justify-center mb-3">
                <FaInstagram className="text-5xl center bg-[#30364E] hover:bg-[#b99c69] border-2 border-solid border-[#444A5F] duration-300 shadow-[#f5c97e] rounded-full p-3" />
              </Typography>
            </a>
          </div>

          {/* Logo */}
          <img src={img} alt="logo-ct" className="w-36" />

          {/* Newsletter Subscription */}
          <div className="relative flex flex-col sm:flex-row w-full max-w-[18rem] items-center sm:items-stretch">
            <Input
              type="email"
              label="Newsletter"
              value={email}
              color="white"
              onChange={onChange}
              className="w-full pr-20 rounded-none"
              placeholder="9XXXXXX or @gmail.com"
              containerProps={{
                className: "min-w-0 rounded-none w-full",
              }}
            />
            <Button
              size="sm"
              color={email ? "#fff" : "white"}
              onClick={handleSubmit}
              className="mt-2 sm:mt-0 sm:ml-2 sm:w-auto w-full rounded-none font-thin text-white bg-[#b99c69] hover:bg-[#f5c97e]"
            >
              Subscribe
            </Button>
          </div>
        </div>
      </section>
<footer className="relative w-full bg-[#262B3E]">
  <div className="mx-auto w-full py-4 md:py-8 px-4 max-w-7xl">
    {/* Main footer content with custom grid */}
    <div className="mx-auto max-w-7xl grid w-full grid-cols-1 gap-8 py-6 sm:grid-cols-[2fr_1fr_1fr]">
      {/* First column (larger) */}
      {SITEMAP.slice(0, 1).map(({ title, links }, key) => (
        <div key={key} className="w-full">
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-4 uppercase text-[#b99c69] font-bold"
          >
            {title}
          </Typography>
          <ul className="space-y-1">
            {links
              .sort((a, b) => a.order - b.order)
              .map((link, key) => (
                <Typography
                  key={key}
                  as="li"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Link
                    to={link.path}
                    className="inline-block py-1 pr-2 transition-transform hover:opacity-100 text-white opacity-80"
                  >
                    {link.title}
                  </Link>
                </Typography>
              ))}
          </ul>
        </div>
      ))}

      {/* Other columns (smaller) */}
      {SITEMAP.slice(1).map(({ title, links }, key) => (
        <div key={key} className="w-full">
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-4 uppercase text-[#b99c69] font-bold"
          >
            {title}
          </Typography>
          <ul className="space-y-1">
            {links
              .sort((a, b) => a.order - b.order)
              .map((link, key) => (
                <Typography
                  key={key}
                  as="li"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Link
                    to={link.path}
                    className="inline-block py-1 pr-2 transition-transform hover:opacity-100 text-white opacity-80"
                  >
                    {link.title}
                  </Link>
                </Typography>
              ))}
          </ul>
        </div>
      ))}
    </div>

    {/* Footer bottom part */}
    <div className="flex w-full flex-col items-center justify-center border-t border-[#3B4051] py-4 sm:flex-row sm:justify-between">
      <Typography
        variant="small"
        className="mb-4 text-center font-normal text-white opacity-80 sm:mb-0"
      >
        &copy; {currentYear}{" "}
        <Link
          to={lowerPart?.copy_right?.path}
          className="hover:underline"
        >
          {lowerPart?.copy_right?.firm_name}
        </Link>
        . {lowerPart?.copy_right?.reserve_text}
      </Typography>

      {/* Privacy Links */}
      <div className="flex gap-4 text-white opacity-90 justify-center sm:justify-end">
        {privacy.map((item, index) => (
          <Typography
            key={index}
            as={Link}
            to={item.path}
            className="opacity-80 transition-opacity hover:opacity-100 hover:underline"
          >
            {item.title}
          </Typography>
        ))}
      </div>
    </div>
  </div>
</footer>


      <ToastContainer />
    </>
  );
};

export default Footer;
