import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import PageHeader from "../common/pageHeader/PageHeader";
// import { AiFillCustomerService } from "react-icons/ai";
import { GoLaw } from "react-icons/go";
import { useLocation, useHistory } from "react-router-dom";
import { apiService } from "../common/apiService";
import Loader from "../common/Loader";

const Services = () => {
  const history = useHistory();
  const location = useLocation();
  const [services, setServices] = useState(location.state?.services);
  const [pageHeader, setPageHeader] = useState(location.state?.pageHeader);
  const [allData, setAllData] = useState(location.state?.allData);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Fetch service data if not available from location state
  useEffect(() => {
    if (!services) {
      const fetchServiceData = async () => {
        try {
          const response = await apiService({ path: "/page/about_us_page", method: "GET" });
          const responseData = await response.json();
          const servicesData = responseData?.data?.page_data?.practice_area?.services;
          setAllData(servicesData);
          setPageHeader({
            backgroundImageUrl: responseData?.data?.page_data?.backgroundImageUrl,
            page_title: "Services",
            page_discription: "We pride ourselves on our client-centric approach, ensuring that each client receives personalized attention and strategic guidance. With a strong emphasis on integrity, transparency, and excellence, JRA Legal Solutions has earned a reputation as a trusted partner in navigating the complexities of the legal landscape in Rajasthan.",
          });
          setServices(servicesData?.[0]); // Set the first service initially
          setDataLoaded(true);
        } catch (error) {
          console.error("Error fetching service data:", error);
        }
      };
      fetchServiceData();
    } else {
      setDataLoaded(true);
    }
  }, [services]);

  // Update services when location state changes
  useEffect(() => {
    if (location.state?.services) {
      setServices(location.state.services);
    }
  }, [location.state]);

  const handleViewAllServices = (index) => {
    history.push({
      pathname: "/Services",
      state: { services: allData[index], allData, pageHeader },
    });
  };

  return (
    <>
      {!dataLoaded ? (
        <Loader />
      ) : (
        <div className="bg-gray-50">
          {/* Page Header */}
          <PageHeader
            backgroundImageUrl={pageHeader?.backgroundImageUrl}
            title={pageHeader?.page_title}
            description={pageHeader?.page_discription}
          />

          {/* Service Details Section */}
          <div className="lg:py-12 py-8 px-6 lg:px-16">
            <div className="max-w-4xl mx-auto text-justify">
              <GoLaw className="text-5xl text-[#c9b38c] mx-auto text-start mb-4" />
              <Typography
                variant="h1"
                color="blue-gray"
                className="text-3xl font-semibold text-gray-800"
              >
                {services?.title}
              </Typography>
              <Typography
                variant="lead"
                className="mt-4 text-lg text-gray-600 leading-relaxed"
              >
                {services?.description}
              </Typography>
            </div>
          </div>

          {/* Other Services Section */}
          <div className="bg-white py-10 px-6 lg:px-16">
            <Typography
              variant="h2"
              color="blue-gray"
              className="text-center text-2xl font-semibold text-gray-800"
            >
              Explore Other Services
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 max-w-6xl mx-auto">
              {allData?.map((item, index) => (
                <div
                  key={index}
                  className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer"
                  onClick={() => handleViewAllServices(index)}
                >
                  <Typography
                    variant="h5"
                    className="text-lg font-medium text-gray-800 mb-2"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="lead"
                    className="text-sm text-gray-600 leading-relaxed"
                  >
                    {item.description?.substring(0, 80)}...
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
