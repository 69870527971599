import "./App.css";
import Pages from "./components/pages/Pages";
import React from "react";
import MainLoader from "./components/common/loader/MainLoader";
import { useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-2642WEGHKK");

// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Custom Title" });


function App() {


  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {

    // new Promise((resolve, reject) => {
    //   if(apiData.length > 0){
    //     resolve()
    //   }
    // })
    // Simulate a data fetching operation
    setTimeout(() => {
      setData({ message: "Data has been loaded!" });
      setLoading(false);
    }, 2000); // Adjust the timeout duration as needed
  }, []);
  return (
    <div className="pages">
      {/* {loading ? (
        <MainLoader />
      ) : (
        <Router>
          <Pages />
        </Router>
      )} */}
         <Router>
          <Pages />
        </Router>
    </div>
  );
}

export default App;
