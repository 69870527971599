import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import "react-toastify/dist/ReactToastify.css";
import { APP_BASE_URL, callingSecureAPI } from '../components/Service/RequestService'
import { apiService } from "../components/common/apiService.js";
import { Button } from "@mui/material";
import Loader from "../components/common/Loader.jsx";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NewLetter() {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [value, setValue] = React.useState(0);
  const isUserPrset = atob(localStorage.getItem("userLoginData"))

  let user = {};
  if (isUserPrset)
    user = JSON.parse(isUserPrset);

  const basePath = APP_BASE_URL
  const [customMonth, setCustomMonth] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [newsLetter, setNewsLetter] = useState('');


  const handleMonthChange = (event) => {
    const { value } = event.target;
    setSelectedMonth(value);
    if (value === 'Other') {
      setCustomMonth('');
    }
  };

  ///submit newletter
  const sumbitNewslLetter = async () => {
    if (!selectedMonth || selectedMonth.length < 5) {
      toast("Please select valid month for news Letter");
      return;
    }
    if (!newsLetter) {
      toast("Please Select a pdf file");
      return;
    }
    if (selectedMonth === 'Other' && !customMonth) {
      toast("Please Enter a valid newsletter title");
      return;
    }

    var jsonBody = {
      "news_letter_month": selectedMonth === 'Other' ? customMonth : selectedMonth,
      "user_id": user?._id
    }
    const formData = new FormData();
    formData.append("files", newsLetter);
    formData.append("data", JSON.stringify(jsonBody));

    try {
      setDataLoaded(true)
      const response = await apiService({
        path: "/newsletter",
        method: "POST",
        body: formData,
      });
      if (response.status === 200) {
        toast("NewsLetter Submitted");
        setNewsLetter('')
        setCustomMonth('')
        setSelectedMonth('')
        callListData();
        setValue(1);
      }
    } catch (error) {
      toast("Something wrong, please try after sometime");
      console.error("Error:", error);
    }
    finally {
      setDataLoaded(false)
    }
  };


  //set active tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "file-upload")
      setNewsLetter(files[0]);
  }


  useEffect(() => {
    callListData();
  }, []);



  ///History Section
  const columns = [
    { id: 'news_letter_month', label: 'Published Month', minWidth: 170 },
    {
      id: 'created_date',
      label: 'Created Date',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'newsletter_c_key',
      label: 'Download News Letter',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
  ];

  //fetching History Data
  const callListData = async (etcParam) => {
    setIsLoading(false)
    try {
      let params = "&currentPage=1&pageSize=" + rowsPerPage
      const response = await callingSecureAPI('/newletterlist?' + (etcParam ? etcParam : params) + params + etcParam, 'GET');
      if (response.status === 200) {
        const responseData = await response.json();
        setDataList(responseData?.data?.paginatedData?.map((item, index) => (
          createData(item)
        )));
        setTotalRecords(responseData?.data?.totalCount[0]?.total)
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(true)
      toast("Something wrong, try later")
    } finally {
      setIsLoading(true)
    }
  };


  function createData(item) {
    const news_letter_month = (item?.news_letter_month ? item?.news_letter_month : "-");
    const created_date = item?.created_date ? getDate(item?.created_date) : "-";
    const newsletter_c_key = item?.newsletter_c_key ? item?.newsletter_c_key : "-";
    return { news_letter_month, created_date, newsletter_c_key };
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    callListData("&currentPage=" + (newPage + 1) + "&pageSize=" + rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    callListData("&currentPage=1&pageSize=" + event.target.value)
  };

  const getDate = (publishDate, createdDate) => {
    const dateString = publishDate ? publishDate : createdDate;
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };


  //DownlaodFile
  const downloadFile = async (value, filename) => {
    const url = APP_BASE_URL + value;
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}_newsletter.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(link.href);
      } else {
        toast("Failed to download file.");
      }
    } catch (error) {
      toast("Error while downloading the file.");
    }
  };

  return (
    <>
      {dataLoaded &&
        <Loader />
      }
      {
        !dataLoaded &&
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="News Letter" {...a11yProps(0)} sx={{ color: '#A69FD6', '&.Mui-selected': { color: '#6558D5' } }} />
              <Tab label="History" {...a11yProps(1)} sx={{ color: '#A69FD6', '&.Mui-selected': { color: '#6558D5' } }} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <section className="relative py-80 bg-blueGray-200 ">
              <div className="container mx-auto px-4">
                <div className="relative flex flex-col min-w-0 break-words  text-[#6558d3] w-full mb-6  -mt-64">
                  <div className="px-6 ">

                    {/* Month Selector */}
                    <div className="mx-0 mb-1 sm:mb-4 col-span-full">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="month-selector"
                      >
                        Select Month of NewsLetter
                      </label>
                      <select
                        id="month-selector"
                        name="month"
                        className="focus:bg-white mb-2 w-full outline-none py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c]"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <option value="" disabled>Select a month</option>
                        <option value="January 2024">January 2024</option>
                        <option value="February 2024">February 2024</option>
                        <option value="March 2024">March 2024</option>
                        <option value="April 2024">April 2024</option>
                        <option value="May 2024">May 2024</option>
                        <option value="June 2024">June 2024</option>
                        <option value="August 2024">August 2024</option>
                        <option value="September 2024">September 2024</option>
                        <option value="October 2024">October 2024</option>
                        <option value="November 2024">November 2024</option>
                        <option value="December 2024">December 2024</option>
                        <option value="Other">Other</option>
                      </select>

                      {selectedMonth === 'Other' && (
                        <div className="mt-5">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="month-selector"
                          >
                            Enter News Letter Title With Month
                          </label>
                          <input
                            type="text"
                            value={customMonth}
                            onChange={(e) => { setCustomMonth(e.target.value) }}
                            placeholder="First Quarter August 2024"
                            className="focus:bg-white w-full outline-none py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal focus:outline-none focus:ring focus:ring-[#c9b38c]"
                          />
                        </div>
                      )}
                    </div>

                    {/* Upload NewsLetter */}
                    <div className="mx-0 mb-1 sm:mb-4 col-span-full">
                      <label
                        htmlFor="name"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Upload New Letter *
                      </label>

                      <div className="">
                        <div className="flex items-center justify-center w-full">
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                PDF (MAX. 5Mb)
                              </p>

                              {
                                newsLetter &&
                                <h4 className="text-xs text-gray-400 dark:text-gray-400">Selected Files: {newsLetter?.name}</h4>
                              }

                            </div>
                            <input
                              className="hidden"
                              onChange={handleInputChange}
                              name="file-upload"
                              accept=".pdf"
                              aria-describedby="file_input_help"
                              id="dropzone-file"
                              type="file"
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Submit button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={sumbitNewslLetter}
                        className="bg-[#6558d5] text-white px-4 py-2 rounded"
                      >
                        Submit
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </section>
          </CustomTabPanel>


          {/* Edit Details Part */}
          <CustomTabPanel value={value} index={1}>
            <Paper sx={{ width: '100%' }}>
              {isLoading &&
                <TableContainer sx={{ maxHeight: "100%" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="h5" component="h5" className="">
                            News Letters History Report
                          </Typography>
                        </TableCell>

                      </TableRow>
                      <TableRow>
                        {columns.map((column, index) => (
                          <TableCell
                            key={column.id + index}
                            align={column.align}
                            style={{ top: 57, minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataList
                        .map((row, index) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.news_letter_month + index}>
                            {columns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id + index} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : (column.id === "newsletter_c_key") ? ''
                                      : value}
                                  {column.id === "newsletter_c_key" && (
                                    <Button variant="contained" color="primary" onClick={() => downloadFile(value, row.news_letter_month)}>
                                      Download File
                                    </Button>
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>}

              {page > 0 &&
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />}
              <ToastContainer />
            </Paper>
          </CustomTabPanel>

          <ToastContainer />
        </Box>}
    </>
  );
}
