import { Avatar } from "@material-tailwind/react";
import React, { useState, useEffect, useCallback } from "react";
import PaginationRounded from "./Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import BlogData from "../Blog.json";
import { apiService, base_image_url } from "../common/apiService.js";
import Modal from "../blog/Model.jsx";
import { IoMdTimer } from "react-icons/io";
const no_image = "no_image";
const BlogSection = ({
  blogs,
  base_path,
  totalPageCount,
  updateBlogRecords,
  refreshPage,
}) => {
  const history = useHistory();
  const basePath = base_image_url;
  let pageData = BlogData?.page_data ? BlogData?.page_data : {};
  const [categoryList, setCategoryList] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [hashTagList, setHashTagList] = useState([]);
  const [newslettersList, setNewslettersList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  //set file Details
  const handleOpenModal = async (fileKey, filename, e) => {
    if (!localStorage.getItem("subscriber_details")) {
      setCurrentFileUrl(fileKey);
      setFileName(filename);
      setIsModalOpen(true);
    } else {
      const url = base_image_url + fileKey;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${filename}_newsletter.pdf`;
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(link.href);
        } else {
          toast.error("Failed to download file.");
        }
      } catch (error) {
        toast.error("Error while downloading the file.");
      }
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const getDate = (publishDate, createdDate) => {
    const dateString = publishDate ? publishDate : createdDate;
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const openBlogSection = (data) => {
    history.push("/read-blog", {
      blogList: blogs,
      categoryList: categoryList,
      blogId: data._id,
      hashTagList: hashTagList,
    });
  };

  //get All Category List
  const getCategoryLsit = async () => {
    setDataLoaded(false);
    try {
      const response = await apiService({
        path: "/counts",
        method: "GET",
      });
      if (response.status === 200) {
        const responseData = await response.json();
        setCategoryList(responseData?.data?.blogCategoryCount);
        setHashTagList(responseData?.data?.LatesthastTags);
        console.log(responseData.data, "categorylist");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setDataLoaded(true);
    }
  };

  //serch blog list
  const searchBlogs = async () => {
    if (searchString.length < 3) {
      toast("Please enter a word more than 3 letters");
      return false;
    }
    try {
      const response = await apiService({
        path: "/search?searchString=" + searchString,
        method: "GET",
      });
      if (response.status === 200) {
        const responseData = await response.json();
        setSearchList(responseData.data);
        console.log(responseData.data, "search list");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //open feture blog page
  const openFeatureBlog = (id, e) => {
    e.preventDefault();
    history.push("/read-blog", {
      blogList: blogs,
      categoryList: categoryList,
      blogId: id,
      hashTagList: hashTagList,
    });
  };

  //get news letter list
  const newletterList = async () => {
    try {
      const response = await apiService({
        path: "/newletterlist?pageSize=6",
        method: "GET",
      });
      if (response.status === 200) {
        const responseData = await response.json();
        setNewslettersList(responseData?.data?.paginatedData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getCategoryLsit();
    newletterList();
  }, []);

  const handlePAginationData = (count) => {
    updateBlogRecords(count);
  };

  const handleChnage = (e) => {
    setSearchString(e.target.value);
  };

  //truncate title with a suitable length
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  //handle search string
  const refreshListView = (searchString, type, event) => {
    let params = "";
    if (type === "category") params = "&category=" + searchString;
    if (type === "taglist")
      params = params + "&tag_list=" + encodeURIComponent(searchString);
    console.log(params);
    refreshPage(params);
  };

  return (
    <>
      <section className="bg-white">
        <div className=" lg:py-8 md:py-5 py-5 px-5  lg:px-10 mx-auto">
          <div className="grid grid-cols-1  xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-1">
            {/* Blog Section */}

            {
              <div className="lg:col-span-2 md:col-span-1 ">
                <div className="grid grid-cols-1 lg:grid-cols-1  gap-6">
                  {blogs.map((blog, index) => (
                    <div
                      key={index}
                      onClick={() => openBlogSection(blog)}
                      className="outline-none w-auto h-full rounded-none overflow-hidden relative cursor-pointer shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] hover:shadow-none transition-all"
                    >
                      <img
                        src={
                          base_path +
                          (blog.files.length > 0
                            ? blog.files[0]?.c_key
                            : no_image)
                        }
                        alt=""
                        className="w-full h-auto lg:h-96 object-cover"
                      />
                      <div className="entry-details relative p-[45px 0 0 20px] border-2">
                        <div className="cat text-[#fff] text-[13px] font-bold px-3 py-2 bg-[#c9b38c] p-3 -left-1 top-[-20px]  rounded-r-full  absolute">
                          {blog?.category?.map((cat, index) => (
                            <a key={index} href="#">
                              {cat}
                            </a>
                          ))}
                        </div>
                        <div className="entry-details relative ps-8 pt-6 pb-1 text-[#687693]">
                          <h2 className="text-[#262B3E] f font-bold text-lg">
                            {blog?.blog_header}
                          </h2>
                          <a
                            onClick={() => openBlogSection(blog)}
                            style={{ textJustify: "center", paddingRight: 10 }}
                          >
                            {blog?.description}
                          </a>
                        </div>
                        <div className="ps-8 pt-2 border-t-2 pb-2 items-center text-gray-600 text-sm">
                          <div className="p-[45px 0 0 20px]">
                            <span>
                              By:{" "}
                              <strong rel="author" className="text-[#AB9163]">
                                {blog?.owner?.name}
                              </strong>
                            </span>
                            <span className="ml-4">
                              {getDate(
                                blog?.published_date,
                                blog?.created_date
                              )}
                            </span>
                            <span
                              className="ml-4"
                              style={{ float: "right", paddingRight: 10 }}
                            >
                              {blog?.tag_list}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {
                  <div style={{ marginTop: 20, float: "right", height: 100 }}>
                    <PaginationRounded
                      count={totalPageCount}
                      onClickCount={handlePAginationData}
                    />
                  </div>
                }
              </div>
            }

            {/* Blog Category and Extra Section */}
            <div className="lg:col-span-1 col-span-3 section-title-s4 pl-5 mb-0 relative">
              <div className="w-full lg:order-1">
                <div className="space-y-8">
                  {/* Search Section */}
                  <div className="p-6 rounded-lg">
                    <h3 className="text-lg font-bold mb-4">Search</h3>
                    <div className="flex rounded-none overflow-hidden w-full border-2 h-12 border-gray-300">
                      <input
                        type="text"
                        value={searchString}
                        onChange={handleChnage}
                        className="w-full bg-white rounded-none focus:outline-none rounded-r-none ml-3"
                        placeholder="Search"
                      />
                      <button
                        onClick={searchBlogs}
                        className="bg-[#c9b38c] text-white px-6 text-lg font-semibold py-2 rounded-r-none"
                      >
                        Go
                      </button>
                    </div>
                    <div className="flex rounded-none overflow-hidden w-full border-2 ">
                      <ul className="space-y-2">
                        {searchList.length > 0 &&
                          searchList.map((blog, index) => (
                            <li key={index}>
                              <a
                                onClick={openFeatureBlog.bind(null, blog?._id)}
                                className="text-[#687693] hover:text-[#c9b38c] duration-100"
                              >
                                {truncateText(blog?.blog_header, 10)}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {/* News Letter Section */}
                  <div className="p-6 rounded-lg">
                    <h3 className="text-lg font-bold mb-4">News Letter</h3>
                    <ul className="space-y-2">
                      {newslettersList.length > 0 &&
                        newslettersList.map((newsletter, index) => (
                          <div key={index} className="flex items-center">
                            <div className="flex w-1/2 items-center">
                              <div className="flex space-x-2">
                                <h4 className="text-sm font-bold">
                                  {newsletter.news_letter_month
                                    ? newsletter.news_letter_month
                                    : getDate(newsletter.created_date)}
                                </h4>
                                <span className="text-gray-600 text-xs flex items-center">
                                  <i className="ti-timer"></i>
                                </span>
                              </div>
                            </div>

                            <div className="flex w-1/2 justify-end">
                              <button
                                onClick={handleOpenModal.bind(
                                  null,
                                  newsletter?.newsletter_c_key,
                                  newsletter?.news_letter_month
                                )}
                                className="flex items-center h-10 bg-[#AB9163]  text-white px-4 py-2 shadow-md hover:shadow-none  rounded-lg transition-all"
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        ))}
                    </ul>
                  </div>

                  {/* Category List with blog counts */}
                  {dataLoaded && (
                    <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                      <h3 className="text-lg font-bold mb-4">Categories</h3>
                      <ul className="space-y-2">
                        {categoryList.length > 0 &&
                          categoryList.map((category, index) => (
                            <li
                              key={index}
                              className="hover:bg-gray-100 cursor-pointer px-2 py-2 rounded-md transition-all"
                            >
                              <a
                                onClick={refreshListView.bind(
                                  null,
                                  category?._id[0],
                                  "category"
                                )}
                                className="text-[#687693] hover:text-[#c9b38c] duration-100"
                              >
                                {category?._id[0]}
                                <span className="">({category?.count})</span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}

                  {/* Recent Post List */}
                  <div className="p-6 rounded-lg bg-gray-50 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] ">
                    <h3 className="text-lg font-bold mb-4">Recent Posts</h3>
                    <div className="space-y-4">
                      {blogs?.map((data, index) => (
                        <div
                          key={index}
                          className="flex gap-2 items-center hover:bg-gray-100 cursor-pointer "
                          onClick={openFeatureBlog.bind(null, data?._id)}
                        >
                          <div
                            href="#"
                            className="w-16 h-16 overflow-hidden rounded-lg flex items-center justify-center "
                          >
                            <Avatar
                              src={basePath + data?.owner?._id}
                              alt="avatar"
                            />
                          </div>
                          <div>
                            <h4 className="text-sm font-bold">
                              <a
                                onClick={openFeatureBlog.bind(null, data?._id)}
                                className="text-[#687693] hover:text-[#c9b38c] duration-100 hover:underline"
                              >
                                {truncateText(data?.blog_header, 5)}
                              </a>
                            </h4>
                            <span className="text-gray-600 text-xs flex items-center gap-1">
                              <IoMdTimer className="w-4 h-4" />
                              {getDate(
                                data?.published_date,
                                data?.created_date
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-6 rounded-lg">
                    <h3 className="text-lg font-bold mb-4">Tags</h3>
                    <div className="flex flex-wrap gap-2">
                      {hashTagList?.map((data, index) => (
                        <a
                          key={index}
                          onClick={refreshListView.bind(null, data, "taglist")}
                          className="bg-gray-200 px-3 py-1 rounded-lg text-gray-800 text-sm hover:text-[#c9b38c] duration-100"
                        >
                          {data}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        fileUrl={base_image_url + currentFileUrl}
        filename={fileName}
      />
    </>
  );
};

export default BlogSection;
