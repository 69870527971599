import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiService } from "../common/apiService";

function Modal({ isOpen, onClose, fileUrl, filename }) {
    const [formValues, setFormValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: ""
    });

    if (!isOpen) return null;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    // Download file
    const downloadFile = async (e) => {
        e.preventDefault();

        if(!formValues.first_name)
        {
            toast("First Name is required field")
            return;
        }
        if(!formValues.last_name)
        {
            toast("Last Name is required field")
            return;
        }
        if (!formValues.email) {
            toast("Email address is a required fields");
            return false;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formValues.email)) {
                toast("Please enter a valid email address");
                return false;
            }
        }
        if (!formValues.phone_number) {
            toast("Phone number is a required fields");
            return false;
        } else if (
            (formValues.phone_number &&
                !isNaN(formValues.phone_number) &&
                !(
                    formValues.phone_number.length === 10 || formValues.phone_number.length === 12
                )) ||
            formValues.phone_number.length === 13
        ) {
            toast("PLease enter a valid phone nubner");
            return false;
        }


        console.log(formValues)
        if(formValues?.email || formValues?.phone_number)
        {
            console.log(formValues)
          try {
            const responseData = await apiService({
              path: '/utility/subcriber',
              method: 'POST',
              body: formValues
            });
              if (responseData.status === 200) {
                  toast("You are added as newsletter subcriber, Thank You")
                  setFormValues({
                      first_name: "",
                      last_name: "",
                      email: "",
                      phone_number: ""
                  })
                  localStorage.setItem("subscriber_details", JSON.stringify(responseData?.data))
            }
          } catch (error) {
            console.error('Error:', error);
          }
        }

        const url = fileUrl;
        try {
            const response = await fetch(url);
            if (response.ok) {
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${filename}_newsletter.pdf`;
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(link.href);
            } else {
                toast.error("Failed to download file.");
            }
        } catch (error) {
            toast.error("Error while downloading the file.");
        }
        onClose(); // Close the modal after download
    };

    return (
        <div className="fixed inset-0  z-[999] flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                <h2 className="text-lg font-bold mb-4">Download Form</h2>
                <form>
                    <div className="mb-4">
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                            First Name 
                        </label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={formValues.first_name}
                            onChange={handleInputChange}
                            autoComplete="given-name"
                            className="mt-1 block w-full h-10 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={formValues.last_name}
                            onChange={handleInputChange}
                            autoComplete="family-name"
                            className="mt-1 block w-full h-10 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            autoComplete="email"
                            className="mt-1 block w-full h-10 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone_number"
                            value={formValues.phone_number}
                            onChange={handleInputChange}
                            autoComplete="tel"
                            className="mt-1 block w-full h-10 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={() => onClose()}
                            className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={downloadFile}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Modal;
