import React from "react";

function PageHeader({ backgroundImageUrl, title, description }) {
  return (
    <header
      className="relative bg-center bg-cover bg-no-repeat p-8 pt-32"
      style={{
        backgroundImage: `url('${backgroundImageUrl}')`,
        width: "100%",
        minHeight: "16rem",
        height: "auto",
        textAlign: "center",
      }}
    >
      {/* Dark Overlay */}
      <div
        className="absolute inset-0 bg-black opacity-50"
        style={{ zIndex: 1 }}
      ></div>
      
      <div className="container mx-auto px-4 text-center relative" style={{ zIndex: 2 }}>
        <h1
          className="mx-auto my-6 w-full leading-snug lg:max-w-3xl text-5xl font-normal text-white"
          style={{ fontFamily: "roboto slab" }}
        >
          {title}
        </h1>
        <p
          className="mx-auto w-full text-white lg:text-lg text-base"
          style={{ fontFamily: "muli" }}
        >
          {description}
        </p>
      </div>
    </header>
  );
}

export default PageHeader;
