import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import PaginationRounded from "../components/blog/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { APP_BASE_URL, callingSecureAPI } from '../components/Service/RequestService'

export default function MySavedBlogs({ handlerMenuLoad }) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageSize, setpPgeSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagename, setPagename] = useState("MySave");
  const [owner_id, setOwner_id] = useState(null);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [basepath, setBasepath] = useState("");
  const [category, setCategory] = useState(null);

  const no_image = "no_image"
  
  //Check user is login or not
  const isUserPrset = atob(localStorage.getItem("userLoginData"))
  let user = {}
  if (isUserPrset && isUserPrset.length > 100)
    user = JSON.parse(isUserPrset)
  else
    history.push('/wp-admin');

  //fetch Data from DB
  const fetchData = async () => {
    let owner_id;
    if (user) {
      owner_id = user?._id
      setOwner_id(owner_id);
    }
    try {
      let params = "?pageSize=" + pageSize + "&currentPage=" + currentPage + "&pagename=" + pagename + "&owner_id=" + owner_id;
      const response = await callingSecureAPI('/blog' + params, 'GET');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (response.status === 200) {
        const result = await response.json();
        setData(result?.data?.paginatedData);
        if (result.data) {
          setBasepath(result?.data?.base_path)
          let count = result?.data?.totalCount[0]?.total ? result?.data?.totalCount[0]?.total : 1
          count = Math.ceil(count / pageSize)
          setTotalPageCount(count)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  //page data Change
  const handleChildCalls = async (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData();
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleSavePost = async (blogDetails, process = null, e) => {
    e.preventDefault();
    let newBlog = {}
    if (process) {
      //create case body
      if (!blogDetails?.owner_id) {
        toast("Something wrong user not found, try later or relogin")
        return false;
      }

      newBlog["_id"] = blogDetails?._id
      //update case body
      if (process === "publish") {
        if (!blogDetails?.blog_header) {
          toast("Blog Header cannot be empty, Please add in Edit Mode")
          return false;
        }
        else if (blogDetails?.blog_header?.length <= 20) {
          toast("Blog Header should be at least 20 characters, Please add in Edit Mode")
          return false;
        }

        if (!blogDetails?.description || blogDetails?.description?.length < 1) {
          toast("Blog description cannot be empty, Please add in Edit Mode")
          return false;
        }
        else if (blogDetails?.description?.length <= 200) {
          toast("Blog description should be at least 150 characters, Please add in Edit Mode")
          return false;
        }

        if (!blogDetails?.category || blogDetails?.category?.length < 1) {
          toast("Blog catogory cannot be empty, Please add in Edit Mode")
          return false;
        }
        else if (blogDetails?.category.length <= 200) {
          toast("Atleast one category should be selected, Please add in Edit Mode")
          return false;
        }

       
        newBlog["is_final"] = true
        if (user?.role === "super_admin") {
          newBlog["published"] = true
          newBlog["reviewed_submitted"] = true
          newBlog["reviewed_by"] = user?.name
          newBlog["reviewed"] = true
        }
        else {
          newBlog["reviewed_submitted"] = true
        }
      }

      if (process === "delete") {
        newBlog["is_deleted"] = true
      }

      //save blog stage to DB
      try {
        const response = await callingSecureAPI('/blog', 'POST', {}, newBlog);
        if (response.status === 200) {
          if (process === "publish") {
            if(user?.position === 'super_admin')
              toast("Blog is Published Succcessfully")
            else 
              toast("Blog is Bubmitted For Review Succcessfully")
            fetchData();
          }
          else if (process === "delete") {
            toast("Save Blog Is Deleted Successfully");
            fetchData();
          }
        } else {
          toast(response.message  || "Something Wrong, Check in Edit Mode");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.log("Form Fields Contain errors");
    }
  };

  const openEditMode = (blogId) => {
    handlerMenuLoad("create_blogs")
    history.push('/wp-admin/dashboard', { blogId: blogId });
  }

  const getDate = (data, type) => {
    const date = new Date(data);
    let options = {};
    if (type === "Date")
      options["day"] = "2-digit"
    if (type === "Month")
      options["month"] = "long"
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }


  const handleCategory = async (category) => {
    if (category) {
      setCategory(category)
      setCurrentPage(1)
      fetchData("&category=" + category + "&currentPage=1")
    }
  }


  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const GridCard = () => {
    return (
      <>
        <div className="max-w-screen-4xl mx-auto p-5 sm:p-10 md:p-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            {data.map((blog, index) => (
              <div key={index} className="rounded overflow-hidden shadow-lg">
                <div className="relative">
                  <a>
                    <img
                      className="w-full h-56 object-cover"
                      src={
                        basepath +
                        (blog.files.length > 0
                          ? blog.files[0]?.c_key
                          : no_image)
                      }
                      alt="Sunset in the mountains"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                  </a>
                  <a onClick={handleCategory.bind(null, blog.category[0] ? blog.category[0] : undefined)}>
                    <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                      {blog.category[0] ? blog.category[0] : "Not Define"}
                    </div>
                  </a>
                  <a>
                    <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                      <span className="font-bold">{getDate(blog?.created_date, "Date")}</span>
                      <small>{getDate(blog?.created_date, "Month")}</small>
                    </div>
                  </a>
                </div>
                <div className="px-6 py-4">
                  <a
                    className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
                  >
                    {truncateText(blog?.blog_header, 5)}
                  </a>
                  <p className="text-gray-500 text-sm">
                    {truncateText(blog?.description, 30)}
                  </p>
                </div>
                <div className="px-6 py-4 flex flex-row  mt-auto items-center">
                <div className="flex justify-between gap-3">
                    <Button
                     variant='contained'
                      onClick={openEditMode.bind(null, blog?._id)}
                      size="small"
                    >
                      Edit
                    </Button>
                    <Button
                     variant='contained'
                      onClick={handleSavePost.bind(null, blog, "publish")}
                      size="small"
                    >
                      {user?.role === "super_admin" ? "Publish" : "Submit"}
                      
                    </Button>
                    <Button
                     variant='contained'
                      onClick={handleSavePost.bind(null, blog, "delete")}
                      size="small"
                    >
                      Delete
                    </Button>
                  </div>
                  {/* </span> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div> Results: {category ? category : "ALL"}</div>
        <GridCard />
        {loading && totalPageCount > 1 && <div style={{ marginTop: 20, float: "right", height: 100 }}><PaginationRounded count={totalPageCount} onClickCount={handleChildCalls} /></div>}

      </Box>
      <ToastContainer />
    </>
  );
}