import React, { useState } from 'react';
import { FaWhatsapp, FaTimes } from 'react-icons/fa';

const WhatsAppWidget = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 z-50 bg-green-500 text-white p-3 rounded-full shadow-lg flex items-center animate-shadow-pulse">
      <a
        href="https://wa.me/9358188430" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center"
      >
        <FaWhatsapp size={30} />
        {/* <span className="hidden sm:block">Chat with us</span> */}
      </a>
      <button
        onClick={handleClose}
        className="absolute -top-1 right-0 bg-black p-1 rounded-full hover:bg-[#c9b38c] transition ease-in-out delay-150 "
      >
        <FaTimes size={12} />
      </button>
    </div>
  );
};

export default WhatsAppWidget;
