import React from "react";
import "./Featured.css";
import { Typography } from '@material-tailwind/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { HiOutlineBriefcase } from "react-icons/hi2";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { base_image_url } from '../../common/apiService';



function TeamCard({ img, name, title }) {
  return (
    <div className="flex items-center justify-center">
      <div className="w-[210px] h-[297px] max-w-[210px] max-h-[297px]"> {/* Fixed size matching A4 dimensions */}
        <Zoom>
          <img
            src={img ? `${base_image_url}${img}` : "defaultUser"}
            alt={name}
            className="w-full h-[290px] object-cover overflow-hidden object-center rounded-lg"
          />
        </Zoom>
      </div>
      {/* <div className="text-center mt-4">
        <h4 className="text-lg font-bold text-gray-800">{name}</h4>
        <p className="text-sm text-gray-600">{title}</p>
      </div> */}
    </div>
  );
}


const Featured = ({ feature_data }) => {

  const settings = {
    dots: true, // Enable dots for better navigation
    arrows: true, // Enable navigation arrows
    infinite: true,
    speed: 800, // Smooth transition
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true, // Pause autoplay when hovered
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <>
      <section className="lg:py-8 md:py-5 py-5 px-5  lg:px-10 ">
  
              <div className="mx-auto">
                <div className="mb-8 text-center lg:mb-8">
                  <Typography className="flex text-[#fff] justify-center mb-3">
                    <HiOutlineBriefcase className='text-4xl center bg-[#c9b38c] shadow-[#f5c97e] rounded-full p-1 font-md' />
                  </Typography>
                  <Typography color="blue-gray" className="text-lg text-md text-[#c9b38c] ml-2">
                    {feature_data?.des_1}
                  </Typography>
                  <Typography variant="h1" color="blue-gray" className="my-2 !text-2xl lg:!text-4xl">
                    {feature_data?.des_2}
                  </Typography>
                  <Typography variant="lead" className="mx-auto w-full text !text-gray-500 max-w-10xl block antialiased font-sans text-sm xl:text-xl text-justify lg:text-xl md:text-xl font-normal leading-relaxed text-inherit max-w-10xl">
                    {feature_data?.des_3}
                  </Typography>
                </div>
                <Slider {...settings} className="px-5 mx-5 md:px-10 lg:px-10 xl:px-10 md:mx-10 lg:mx-10 xl:mx-10  ">
                  {feature_data?.members?.map((props, key) => (
                    <TeamCard key={key} {...props} />
                  ))}
                </Slider>
              </div>
      </section>
    </>
  );
};

export default Featured;
