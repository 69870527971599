import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { apiService } from "../common/apiService.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./jointeam.css";

const JoinTeam = ({ data }) => {
  const pagedata = data;
  let career_content_info = pagedata?.career_content_info;
  let application_form = career_content_info?.application_form;
  const history = useHistory();

  const [formValues, setFormValues] = useState({
    position: "",
    first_name: "",
    last_name: "",
    email: "",
    number: "",
    experiance: "",
    available_data: "",
    additional_info: "",
    fimagePath: null,
  });

  const handleDateChange = (date) => {
    handleInputChange({
      target: {
        name: "available_data",
        value: date,
      },
    });
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "file-upload") {
      setFormValues({
        ...formValues,
        imagePath: files[0],
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!formValues.position || formValues.position.length < 3) {
      toast("Position title is a required fields Or Enter Valid Value");
      return false;
    } else if (!formValues.first_name || formValues.first_name.length < 3) {
      toast("First name is a required fields or Enter valid first name");
      return false;
    } else if (!formValues.last_name || formValues.last_name.length < 3) {
      toast("Last name is a required fields Or Enter valid last name");
      return false;
    } else if (!formValues.email) {
      toast("Email address is a required fields");
      return false;
    } else if (!formValues.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formValues.email)) {
        toast("Please enter a valid email address");
        return false;
      }
    } else if (!formValues.number) {
      toast("Phone number is a required fields");
      return false;
    } else if (
      (formValues.number &&
        !isNaN(formValues.number) &&
        !(
          formValues.number.length === 10 || formValues.number.length === 12
        )) ||
      formValues.number.length === 13
    ) {
      toast("PLease enter a valid phone nubner");
      return false;
    } else if (!formValues.experiance || formValues.experiance.length === 0) {
      toast("Experiance is a required fields");
      return false;
    } else if (formValues.experiance && formValues.experiance.length > 0) {
      if (isNaN(formValues.experiance)) {
        toast("Experiance enter in as number like : 3 , 4");
        return false;
      }
    }
    return true;
  };
  const checkDateAndResume = () => {
    console.log(formValues, "here");
    if (
      formValues.available_data.length === 0 ||
      formValues.available_data.length < 3
    ) {
      toast("Available from date is a required fields");
      return false;
    }
    return true;
  };

  const handleform = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!checkDateAndResume()) return;

    let data = {
      position: formValues.position,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      email: formValues.email,
      phone_number: formValues.number,
      experiance: formValues.experiance,
      available_data: formValues.available_data,
      message: formValues.additional_info,
      page_c_key: "career_from",
    };
    let file = formValues["imagePath"];
    if (!file) {
      toast("Your Resume is missing");
      return false;
    }
    // Check if the file is a PDF
    if (file.type !== "application/pdf") {
      toast("Only PDF files are allowed");
      return;
    }
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("files", formValues.imagePath);

    try {
      const response = await apiService({
        path: "/career",
        method: "POST",
        body: formData,
      });
      if (response.status === 200) {
        toast("Your Details Is Submitted, Our Teach Will Get Back You.");
        history.push("/Career");
        setFormValues({
          position: "",
          first_name: "",
          last_name: "",
          email: "",
          number: "",
          experiance: "",
          available_data: "",
          additional_info: "",
          fimagePath: null,
        });
      }
    } catch (error) {
      toast("YSomething wrong, please try after sometime");
      console.error("Error:", error);
    }
    //console.log('Form values:', data, file, formData);
  };

  return (
    <>
      <section className="bg-[#fff] dark:bg-slate-800" id="contact">
        <div className="mx-auto  px-4 py-4 md:py-8 lg:py-8 xl:py-8  md:px-8 lg:px-8 xl:px-8">
          {/* <div className="mb-4">
            <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              <p className="text-base font-semibold uppercase tracking-wide text-[#c9b38c] dark:text-blue-200">
                Join Our Team
              </p>
              <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                Get in Touch
              </h2>
              <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400">
                Flourish under the mentorship of our highly experienced team
              </p>
            </div>
          </div> */}

          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2 ">
              <div className="h-fit max-w-6xl px-5 py-5  md:px-5 md:py-5">
                  <h1 className="text-uppercase text-black text-4xl font-bold mb-4">
                    Careers
                  </h1>
                  <p className="leading-1 mb-4 mt-4 text-justify">
                    JRA legal solutions is
                    always interested to hear from talented, motivated,
                    enthusiastic, and committed professionals who share our
                    ideals in achieving excellence.
                  </p>
                  <p className="leading-1 mb-4 mt-4 text-justify">
                    Our philosophy in crafting future excellence hinges on
                    exposure to a variety of practice areas, enabling lawyers to
                    understand the big picture of the business risks and
                    exposure that our clients face.
                  </p>
                  <p className="leading-1 mb-4 mt-4 text-justify">
                    We maintain a low ratio of partners to associates, giving
                    those who are capable a high level of autonomy in managing
                    matters and dealing with clients.
                  </p>
                  <p className="leading-1 mb-4 mt-4 text-justify">
                    We look for exceptional professionals, graduates, and
                    associates who can demonstrate exceptional analytical and
                    problem-solving potential, with a high standard of
                    professional ethics commensurate with our values and
                    culture.
                  </p>
                  <p className="leading-1 mb-4 mt-4 text-justify">
                    During our selection process, be prepared to demonstrate
                    those abilities on your feet, whether it's a response to
                    general logical problems or your assessment of a particular
                    case study that fits with your area of expertise.
                  </p>
                  <h2 className="text-2xl font-bold text-black mb-2">
                    Careers
                  </h2>
                  <p className="leading-1 mb-4 mt-4 text-justify">
                    Should you wish to be considered for a career with{" "}
                    JRA legal solutions, please send a one-page CV and a one-page covering letter or
                    short e-mail, stating the areas of law you're interested in
                    and the office you'd like to work with, to:{" "}
                    <a href="mailto:legalsolutionsjra@gmail.com">
                      legalsolutionsjra@gmail.com
                    </a>
                  </p>
                  <h2 className="text-2xl font-bold text-black mb-2">
                    Internships
                  </h2>
                  <p className="leading-1 text-justify">
                    Should you wish to be considered for an internship, please
                    send a one-page CV and a one-page covering letter or short
                    e-mail, stating the areas of law you're interested in and
                    the office you'd like to work with, to:{" "}
                    <a href="mailto:legalsolutionsjra@gmail.com">
                      legalsolutionsjra@gmail.com
                    </a>
                  </p>
              </div>
              <div className="h-fit max-w-6xl p-5 md:px-5 md:py-5" id="form" onSubmit={handleform}>
                <p className="text-base font-semibold uppercase tracking-wide text-[#c9b38c] dark:text-blue-200 ">
                  {application_form?.application_title}
                </p>
                <h2 className="mb-4 text-2xl font-bold dark:text-white">
                  {application_form?.title}
                </h2>

                <form id="contactForm">
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="name"
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          {" "}
                          {application_form?.details[0]?.title}
                          {application_form?.details[0]?.star}
                        </label>
                        <input
                          id="position"
                          name="position"
                          value={formValues.position}
                          onChange={handleInputChange}
                          type="text"
                          autoComplete="given-name"
                          placeholder="Position Title"
                          className="mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] "
                        />
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <div className="flex flex-wrap -mx-3 mb-6">
                          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-first-name"
                            >
                              {application_form?.details[1]?.title}
                              {application_form?.details[1]?.star}
                            </label>
                            <input
                              className=" focus:bg-white mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] "
                              id="first_name"
                              name="first_name"
                              value={formValues.first_name}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="First Name"
                            />
                            {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                          </div>
                          <div className="w-full md:w-1/2 px-3">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-last-name"
                            >
                              {application_form?.details[2]?.title}
                              {application_form?.details[2]?.star}
                            </label>
                            <input
                              className=" focus:bg-white mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] "
                              id="last_name"
                              name="last_name"
                              value={formValues.last_name}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <div className="flex flex-wrap -mx-3 mb-6">
                          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-first-name"
                            >
                              {application_form?.details[3]?.title}
                              {application_form?.details[3]?.star}
                            </label>
                            {/* <input type="text" id="name" autoComplete="given-name" placeholder="Position Title" className="mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] " name="name" /> */}
                            <input
                              className=" focus:bg-white mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] "
                              id="email"
                              name="email"
                              value={formValues.email}
                              onChange={handleInputChange}
                              type="Email"
                              placeholder="name@mail.com"
                            />
                            {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                          </div>
                          <div className="w-full md:w-1/2 px-3">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-last-name"
                            >
                              {application_form?.details[4]?.title}
                              {application_form?.details[4]?.star}
                            </label>
                            <input
                              maxLength={10}
                              className=" focus:bg-white mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] "
                              id="number"
                              name="number"
                              value={formValues.number}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="Contact Number"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mx-0 mb-1 sm:mb-4">
                        <div className="flex flex-wrap -mx-3 mb-6">
                          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="mx-0 mb-1 sm:mb-4">
                              <label
                                htmlFor="name"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              >
                                {application_form?.details[5]?.title}{" "}
                                {application_form?.details[5]?.star}
                              </label>
                              <input
                                type="text"
                                id="experiance"
                                name="experiance"
                                value={formValues.experiance}
                                onChange={handleInputChange}
                                autoComplete="given-name"
                                placeholder="Number of related years of experience"
                                className="mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] "
                              />
                            </div>
                            {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                          </div>
                          <div className="w-full md:w-1/2 px-3">
                            <div className="mx-0 mb-1 sm:mb-4">
                              <label
                                htmlFor="available_data"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              >
                                {application_form?.details[6]?.title}
                                {application_form?.details[6]?.star}
                              </label>
                              <DatePicker
                                selected={formValues.available_data}
                                onChange={handleDateChange}
                                dateFormat="yyyy/MM/dd"
                                className="mb-2 w-full  outline-none flex  py-2 pl-2 pr-4 text-gray-500  bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none  focus:ring-[#c9b38c]"
                                placeholderText="Select a date"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="name"
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          {application_form?.details[6]?.title}
                          {application_form?.details[6]?.star}
                        </label>
                        <input
                          type="date"
                          id="available_data"
                          name="available_data"
                          value={formValues.available_data}
                          onChange={handleInputChange}
                          autoComplete="given-name"
                          placeholder="Position Title"
                          className="mb-2 w-full  outline-none  py-2 pl-2 pr-4 text-gray-500  bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none  focus:ring-[#c9b38c] "
                        />
                      </div> */}

                      <div className="mx-0 mb-1 sm:mb-4 col-span-full">
                        <label
                          htmlFor="name"
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          {" "}
                          {application_form?.details[7]?.title}
                          {application_form?.details[7]?.star}
                        </label>

                        <div className="">
                          {/* <div className="text-center">
                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                              >
                                <span className="uppercase tracking-wide  text-xs font-bold ">
                                  Upload a file
                                </span>
                                <input
                                  onChange={handleInputChange}
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  accept=".pdf"
                                  className="sr-only"
                                  aria-describedby="file_input_help"
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs leading-5 text-gray-600">
                              PDF up to 2MB
                            </p>
                          </div> */}
                          <div className="flex items-center justify-center w-full">
                            <label
                              htmlFor="dropzone-file"
                              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>{" "}
                                  or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                  PDF (MAX. 1Mb)
                                </p>
                              </div>
                              <input
                                className="hidden"
                                onChange={handleInputChange}
                                // id="file-upload"
                                name="file-upload"
                                accept=".pdf"
                                // className="sr-only"
                                aria-describedby="file_input_help"
                                id="dropzone-file"
                                type="file"
                                //  className="hidden"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="name"
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          {" "}
                          {application_form?.details[8]?.title}
                          {application_form?.details[8]?.star}
                        </label>

                        <textarea
                          id="additional_info"
                          name="additional_info"
                          value={formValues.additional_info}
                          onChange={handleInputChange}
                          rows={3}
                          className="block rounded-md border-0 mb-2 w-full  outline-none  py-2 pl-2 pr-4 bg-[#F4F4F4] font-normal sm:mb-0 focus:outline-none focus:ring focus:ring-[#c9b38c] sm:text-sm sm:leading-6"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="w-full bg-[#c9b38c] text-white px-6 py-3 font-xl rounded-none  hover:bg-[#b99c69] sm:mb-0"
                    >
                      {application_form?.details[9]?.title}
                      {application_form?.details[9]?.star}
                    </button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinTeam;
