import React, { useState } from "react";

const MediaSection = ({ media_content_info, base_path }) => {
  media_content_info.sort((a, b) => a.order - b.order);

  const [expandedState, setExpandedState] = useState(
    media_content_info.map(() => false)
  );

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleToggle = (index) => {
    const newExpandedState = [...expandedState];
    newExpandedState[index] = !newExpandedState[index];
    setExpandedState(newExpandedState);
  };

  return (
    <>
    <section className="bg-white py-12">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4">
          <div className="col-span-2">
            {media_content_info.map((item, index) => (
              <div key={index} className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:py-8 py-4">
                <div
                  className={`border-none w-auto h-full rounded-md overflow-hidden ${
                    item.order % 2 !== 0 ? "" : "order-first xl:order-last"
                  }`}
                >
                  <img
                    src={base_path+item.image}
                    alt={item.title}
                    className="w-full h-auto lg:h-full object-cover object-center px-2 lg:px-0"
                  />
                </div>
                <div className="border-none w-full lg:w-auto h-full top-0 rounded-none overflow-hidden relative flex flex-col justify-between p-6 lg:p-0">
                  <div className="entry-details">
                    <h2 className="text-3xl text-[#262B3E] font-bold">
                      {item.title}
                    </h2>
                    <p className="pt-2 text-[#687693] text-justify">
                      {expandedState[index]
                        ? item.description
                        : truncateText(item.description, 120)}
                      {item.description.split(" ").length > 100 && (
                        <span
                          onClick={() => handleToggle(index)}
                          className="text-blue-500 cursor-pointer"
                        >
                          {expandedState[index] ? " Read Less" : " Read More"}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    {/* <div class="space-y-12">
    <div class="flex flex-col md:flex-row items-center md:items-start md:justify-between">
      <div class="md:w-5/12"> 
        <h2 class="text-2xl font-bold mb-4">Verse Finder</h2>
        <p class="text-gray-600 mb-6 text-center md:text-left">Easily search for verses from the Old and New Testaments by entering any topic, concept, or keyword. The Verse Finder helps you quickly locate relevant scriptures and provides insightful context to deepen your understanding of the Bible's teachings.</p>
      </div>
      <div class="md:w-5/12 max-w-sm">
        <img class="rounded-md border-none" src="https://i.imgur.com/Q9RPPO0.png" alt="Verse Finder Image"/>
      </div>
    </div>
    <div class="flex flex-col md:flex-row-reverse items-center md:items-start md:justify-between">
      <div class="md:w-5/12"> 
        <h2 class="text-2xl font-bold mb-4">Bible Trivia</h2>
        <p class="text-gray-600 mb-6 text-center md:text-left">Challenge yourself with Bible Trivia and sharpen your biblical knowledge through engaging games. Test your memory on biblical events, characters, locations, and teachings. Choose your difficulty level and enjoy a fun, personalized learning journey at your own pace.</p>
      </div>
      <div class="md:w-5/12 max-w-sm">
        <img class="rounded-md border-none" src="https://i.imgur.com/h7TsTPl.png" alt="Bible Trivia Image"/>
      </div>
    </div>
  </div> */}
    </>
  );
};

export default MediaSection;
