import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="text-gray-100 bg-[#27272A] px-6 sm:pt-28 lg:pt-28 pt-28 py-6">
      <h2 className="text-3xl font-bold mb-4">
        <strong>Terms and Conditions</strong>
      </h2>

      <p className="mb-4">Welcome to www.jralegalsolution.com!</p>

      <p className="mb-4">
        These terms and conditions outline the rules and regulations for the use of JRA Legal Solutions's Website, located at{' '}
        <a
          href="https://www.jralegalsolution.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#AB9163] hover:underline"
        >
          www.jralegalsolution.com
        </a>
        .
      </p>

      <p className="mb-4">
        By accessing this website we assume you accept these terms and conditions. Do not continue to use www.jralegalsolution.com if you do not agree to take all of the terms and conditions stated on this page.
      </p>

      <p className="mb-4">
        The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice, and all Agreements: "Client", "You", and "Your" refers to you, the person logging on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our", and "Us" refers to our Company. "Party", "Parties", or "Us" refers to both the Client and ourselves.
      </p>

      <h3 className="text-2xl font-bold mt-6 mb-4"><strong>Cookies</strong></h3>

      <p className="mb-4">
        We employ the use of cookies. By accessing www.jralegalsolution.com, you agreed to use cookies in agreement with JRA Legal Solutions's Privacy Policy.
      </p>

      <p className="mb-4">
        Most interactive websites use cookies to retrieve user details for each visit. Cookies enable certain areas of our website to function and make it easier for users. Some of our affiliate/advertising partners may also use cookies.
      </p>

      <h3 className="text-2xl font-bold mt-6 mb-4"><strong>License</strong></h3>

      <p className="mb-4">
        Unless otherwise stated, JRA Legal Solutions and/or its licensors own the intellectual property rights for all material on www.jralegalsolution.com. All intellectual property rights are reserved. You may access this from www.jralegalsolution.com for your own personal use, subject to restrictions outlined in these terms and conditions.
      </p>

      <ul className="list-disc list-inside pl-4 mb-6">
        <li className="mb-2">Republish material from www.jralegalsolution.com</li>
        <li className="mb-2">Sell, rent, or sub-license material from www.jralegalsolution.com</li>
        <li className="mb-2">Reproduce, duplicate, or copy material from www.jralegalsolution.com</li>
        <li>Redistribute content from www.jralegalsolution.com</li>
      </ul>

      <p className="mb-6">
        This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the{' '}
        <a
          href="https://www.termsandconditionsgenerator.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#AB9163] hover:underline"
        >
          Free Terms and Conditions Generator
        </a>
        .
      </p>

      {/* Other sections like Hyperlinking, iFrames, Disclaimer */}
      
      <h3 className="text-2xl font-bold mt-6 mb-4"><strong>Disclaimer</strong></h3>
      <p className="mb-4">
        To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
      </p>

      <ul className="list-disc list-inside pl-4 mb-6">
        <li>limit or exclude our or your liability for death or personal injury;</li>
        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
        <li>limit any of our or your liabilities in any way that is not permitted under applicable law;</li>
        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
      </ul>

      <p className="">
        As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
      </p>
    </div>
  );
};

export default TermsAndConditions;
