import React, { useState, useEffect } from "react";
import PageHeader from "../common/pageHeader/PageHeader";
import { apiService } from "../../components/common/apiService";
import Loader from "../common/Loader.jsx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


const Faq = () => {
  const [pageData, setPageData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService({
          path: "/utility/common_faqs",
          method: "GET",
        });
        const responseData = await response.json();
        setPageData(responseData?.data?.data_source);
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {!dataLoaded && <Loader />}
      {dataLoaded && (
        <>
          <PageHeader
            backgroundImageUrl={pageData.backgroundImageUrl}
            title={"Services"}
            description={pageData.page_discription}
          />
          <div className="mx-auto px-4 py-2 md:py-12">
            <div className="max-w-7xl mx-auto flex items-stretch justify-center">
              {/* Page Title and Description */}
              <div className="h-fit text-center max-w-6xl p-5 md:px-5 md:py-5  flex flex-col justify-center">
                <h1 className="text-base font-semibold uppercase tracking-wide text-[#c9b38c] dark:text-blue-200">
                  {pageData?.title}
                </h1>
                <p className="mb-4  text-2xl font-bold dark:text-white">
                  {pageData?.description}
                </p>
                
              {/* FAQ Items */}
              <div className="text-start">
                {pageData?.FAQ?.map((item, index) => (
                  <div key={index} className="mb-4">
                    {/* Question Button */}
                    <button
                      onClick={() => toggleAnswer(index)}
                      className="w-full text-left px-4 py-3 md:py-4 bg-white border border-gray-300 rounded-lg shadow-sm flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-[#AB9163] transition-all duration-300"
                    >
                      <h2 className="text-base md:text-lg font-medium">
                        {item.question}
                      </h2>
                      {activeIndex === index ? (
                        <IoIosArrowUp className="w-5 h-5 md:w-6 md:h-6 text-[#AB9163] transition-transform duration-300" />
                      ) : (
                        <IoIosArrowDown className="w-5 h-5 md:w-6 md:h-6 text-[#AB9163] transition-transform duration-300" />
                      )}
                    </button>

                    {/* Answer Section */}
                    <div
                      className={`overflow-hidden transition-all duration-500 ease-in-out ${
                        activeIndex === index
                          ? "max-h-[500px] opacity-100"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      <div className="mt-2 px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
                        <div
                          className="text-sm md:text-base leading-relaxed"
                          dangerouslySetInnerHTML={{
                            __html: item?.answer,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <p className="py-4 text-2xl font-bold dark:text-white">
                  Last updated: {pageData?.last_update}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Faq;
