import { Typography } from "@material-tailwind/react";
import React from "react";
import IconsHandler from "../common/IconHandler/IconHandler";
import img from "../images/jra/jra_img.jpg";
import { useHistory } from 'react-router-dom';

const LegalPracticeSection = ({ pageData , backgroundImageUrl}) => {
  const history = useHistory();
  let data = pageData?.practice_area?.services ? pageData?.practice_area?.services : [];
  data.sort((a, b) => a.order - b.order);

  const handleViewAllServices = (index) => {
    history.push({
      pathname: "/Services",
      state: {
        services: data[index],
        allData: data,
        pageHeader: {
          backgroundImageUrl: pageData?.backgroundImageUrl,
          page_title: "Services",
          page_discription: "We pride ourselves on our client-centric approach, ensuring that each client receives personalized attention and strategic guidance. With a strong emphasis on integrity, transparency, and excellence, JRA Legal Solutions has earned a reputation as a trusted partner in navigating the complexities of the legal landscape in Rajasthan."
        }
      }
    });
  };

  return (
    
    <div className="bg-gray-100">
      <div className="text-center mb-8">
        <span className="text-sm text-[#c9b38c] font-bold">
          QUALIFIED ATTORNEYS
        </span>
        <h2 className="text-2xl md:text-4xl lg:text-4xl font-normal text-gray-900 mt-2">
          Our Services
        </h2>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-4 sm:py-4 lg:max-w-none lg:py-4">
          <div className="lg:grid lg:grid-cols-2 lg:space-y-0 rounded-3xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] overflow-hidden">
            <div className="relative h-full w-full overflow-hidden bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-full">
              <img
                src={img}
                alt="t"
                className="h-full w-full object-cover object-center"
              />
            </div>

            <div className="grid  auto-rows-max bg-gray-800 p-3 gap-4 ">
              <div className="flex ">
                <div className="flex mr-4 items-center text-xl">
                  <IconsHandler name={pageData.icon} />
                </div>
                <div className="flex flex-col mr-4 items-start text-lg">
                  <p className="font-semibold text-xl text-gray-50">
                    {pageData.title}
                  </p>
                  <p variant="paragraph" className="text-sm text-gray-300">
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 lg:gap-6 xl:gap-6 auto-cols-auto">
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="flex py-2 items-center text-white"
                  >
                    <div className="xl:p-3 lg:p-3 md:p-2 p-1 sm:mr-2 text-md">
                      <IconsHandler name={item.icon} />
                    </div>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => handleViewAllServices(index)}
                    >
                      <Typography
                        variant="h3"
                        className="font-light text-md mr-2"
                      >
                        {item.title}
                      </Typography>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default LegalPracticeSection;
