import React, { useState, useEffect } from 'react';
import PageHeader from '../common/pageHeader/PageHeader';
import MediaSection from './MediaSection';
import MediaData from '../Media.json'
import { apiService } from "../common/apiService.js";
import Loader from "../common/Loader.jsx"
const Media = () => {
  const [mediaData, setMediaData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await apiService({
        path: "/page/media_page",
        method: "GET"
      });
      const responseData = await response.json();
      setMediaData(responseData.data);
      setDataLoaded(true);
    } catch (error) {
      console.error('Error:', error);
      setMediaData(MediaData); // Set mediaData to local data in case of error
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  let first_section = mediaData?.page_data ? mediaData?.page_data : []

  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && <PageHeader
        backgroundImageUrl={first_section?.backgroundImageUrl}
        title={first_section?.page_title}
        description={first_section?.page_discription}
      />}
      {dataLoaded && <MediaSection media_content_info={first_section?.media_content_info} base_path={first_section?.base_path} />}
    </>
  );
}

export default Media;
