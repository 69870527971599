import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { Button } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography";
import { APP_BASE_URL, callingSecureAPI } from '../components/Service/RequestService';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'ISO\u00a0Email', minWidth: 100 },
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 130,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'position',
    label: 'Job Role',
    minWidth: 150,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'experiance',
    label: 'Experiance',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'available_data',
    label: 'Abailabel Date',
    minWidth: 130,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'resume_files_ckey',
    label: 'Action\u00a0',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  }
];

export default function CareerQueries() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const callListData = useCallback(async (etcParam) => {
    try {
      let params = "&currentPage=1&pageSize=" + rowsPerPage;
      const response = await callingSecureAPI('/contactus/list?page_c_key=career_from' + (etcParam ? etcParam : params) + params + etcParam, 'GET');
      if (response.status === 200) {
        const responseData = await response.json();
        setDataList(responseData?.data?.paginatedData?.map((item) => (
          createData(item)
        )));
        setTotalRecords(responseData?.data?.totalCount[0]?.total);
      }
    } catch (error) {
      console.error('Error:', error);
      toast("Something went wrong, try later");
    } finally {
      setIsLoading(true);
    }
  }, [rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    callListData("&currentPage=" + (newPage + 1) + "&pageSize=" + rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    callListData("&currentPage=1&pageSize=" + event.target.value);
  };

  const handleButtonClick = async (value, firstName) => {
    const url = APP_BASE_URL + value;
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${firstName}_resume.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(link.href);
      } else {
        toast("Failed to download file.");
      }
    } catch (error) {
      toast("Error while downloading the file.");
    }
  };

  function createData(item) {
    const name = `${item?.first_name} ${item?.last_name}`;
    const email = item?.email ? item?.email : "-";
    const phone_number = item?.phone_number ? item?.phone_number : "-";
    const position = item?.position ? item?.position : "-";
    const experiance = item?.experiance ? item?.experiance : "-";
    const available_data = item?.available_data?.substring(0, 10);
    const resume_files_ckey = item?.resume_files_ckey;
    const first_name = item?.first_name;
    return { name, email, phone_number, position, experiance, available_data, resume_files_ckey, first_name };
  }

  useEffect(() => {
    callListData();
  }, [callListData]);

  return (
    <Paper sx={{ width: '100%' }}>
      {isLoading && (
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  <Typography variant="h5" component="h5" className="">
                    Career Queries
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id + index}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name + index}>
                    {columns.map((column, index) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id + index} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : (column.id === "resume_files_ckey") ? ''
                              : value}
                          {column.id === "resume_files_ckey" && (
                            <Button variant="contained" color="primary" onClick={() => handleButtonClick(value, row.name)}>
                              Download Resume
                            </Button>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </Paper>
  );
}
