import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import IconsHandler from "../common/IconHandler/IconHandler";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {apiService} from "../common/apiService.js";

const ContactSection = ({ contact_info, form_info }) => {
  // const history = useHistory();
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    number: '',
    services_list: '',
    message: '',
    subscribe_news_letter: false
  });
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const contactInfo = [...contact_info].sort((a, b) => a.order - b.order);
  const formData = form_info?.form_data ? [...form_info.form_data].sort((a, b) => a.order - b.order) : [];
  const service_list = form_info?.service_list ? [...form_info.service_list].sort((a, b) => a.order - b.order) : [];
console.log("formData      formData ==",service_list);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    setErrors({ ...errors, subscribe: "" });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    // let formErrors = {};

    if (!formValues.first_name || formValues.first_name.length < 3) {
      toast("First name is required")
      return false;
    }
    if (!formValues.last_name || formValues.last_name.length < 3) {
      toast("Last name is required")
      return false;
    }
    if (!formValues.email) {
      toast("Email is required")
      return false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      toast("Email is invalid")
      return false;
    }
    if (!formValues.number) {
      toast("Contact number is required")
      return false;
    } else if (!/^\d{10}$/.test(formValues.number)) {
      toast("Contact number is invalid")
      return false;
    }
    if (!formValues?.services_list || formValues?.services_list?.length === 0) {
      toast("Please select a service")
      return false;
    }
    if (!formValues.message) {
      toast("Message is required")
      return false;
    }

    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsButtonDisabled(true);

    console.log(formValues,"here")
    const data = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      email: formValues.email,
      phone_number: formValues.number,
      message: formValues.message,
      subscribe_news_letter: checked,
      service_id: formValues.services_list,
      page_c_key: "contact_us_form"
    };

    try {
      const response = await apiService({
        path: "/contactus",
        method: "POST",
        body: data
      });
      if (response.status === 200) {
        toast("Thank You for form submission!! Our expert will contact you soon..!!");
        Redirect('/Contact')
        setFormValues({
          first_name: '',
          last_name: '',
          email: '',
          number: '',
          message: '',
          services_list: '',
          subscribe_news_letter: false,
        });
        setChecked(false);
      }
    } catch (error) {
      toast("Something went wrong, please try again later");
      console.error('Error:', error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <section className="lg:py-8 md:py-5 py-5 px-5 lg:px-10 ">
      <div className="mx-auto">
          <div className="mb-4">
            <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              <p className="text-base font-semibold uppercase tracking-wide text-[#c9b38c] dark:text-blue-200">
              Our Offices
              </p>
              <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                Get in Touch
              </h2>
              <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400">
              Let's Stay in Touch! Connect with us via phone, email, helpdesk form or Social Media.
              </p>
            </div>
          </div>
        <div className=" bg-white grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-0 md:gap-5 lg:gap-5 xl:gap-5">
          {/* Contact Info */}
          <div className=" mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-8">
            {contactInfo.map((item, index) => (
              <div key={index} className="flex items-center mb-4">
                <IconsHandler name={item?.icon} />
                <div>
                  <h4 className="text-lg text-[#c9b38c] font-semibold">{item.title}</h4>
                  <div className=" ">
                    <a href="https://maps.app.goo.gl/hbLsTGG7VjxYWWgHA" className="hover:decoration-[#c9b38c] hover:underline">
                    {item.description}
                    </a>
                    
                    </div>
                </div>
              </div>
            ))}
          </div>

          {/* Contact Form */}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-8">
            <div className="flex text-center flex-col md:flex-row lg:flex-row  xl:flex-row mb-8 transition-all">
              <div className="p-0 md:p-3 lg:p-3 xl:p-3 pt-0 text-3xl mb-0 md:mb-4 lg:mb-4 xl:mb-4">
                <IconsHandler name={form_info?.icon} />
              </div>
              <div>
                <h2 className="text-2xl lg:text-lg text-start text-[#c9b38c] mb-4">{form_info?.icon_heading}</h2>
                <h2 className="text-start text-4xl">{form_info?.title}</h2>
                <p className="text-gray-700 text-justify">{form_info?.description}</p>

                {/* Form Section */}
                <form className="space-y-4 mt-4" onSubmit={handleFormSubmit}>
                  <div className="grid grid-cols-2 gap-4">
                    {formData.map((item, index) => (
                      form_info?.list_value.includes(item.c_key) && (
                        <input
                          key={index}
                          type="text"
                          id={item.title + index}
                          className="w-full p-3 border border-gray-300 rounded-md"
                          placeholder={item.title}
                          aria-label={item.title}
                          name={item.c_key}
                          onChange={handleInputChange}
                          value={formValues[item.c_key] || ''}
                        />
                      )
                    ))}
                  </div>

                  {formData.map((item, index) => (
                    item.c_key === "services_list" && (
                      <div key={index}>
                        <select
                          onChange={handleInputChange}
                          className="w-full p-3 border border-gray-300 rounded-md"
                          name={item.c_key}
                          value={formValues[item.c_key] || ''}
                        >
                          <option value="">Select an option</option>
                          {service_list.map((optionItem, optionIndex) => (
                            <option key={optionIndex} value={optionItem}>
                              {optionItem}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                  ))}

                  {/* Textarea for message */}
                  {formData.map((item, index) => (
                    item.c_key === "message" && (
                      <textarea
                        key={index}
                        rows="5"
                        className="w-full p-3 border border-gray-300 rounded-md"
                        placeholder="Case Description..."
                        aria-label="Case Description"
                        onChange={handleInputChange}
                        name={item.c_key}
                        value={formValues[item.c_key] || ''}
                      ></textarea>
                    )
                  ))}

                  {/* Checkbox for subscribe */}
                  {formData.map((item, index) => (
                    item.c_key === "subscribe" && (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={item.title + index}
                          className="mr-2"
                          aria-label={item.title}
                          checked={checked}
                          onChange={handleCheckboxChange}
                          name={item.c_key}
                        />
                        <label htmlFor={item.title + index}>{item.title}</label>
                        {errors.subscribe && <span className="text-red-500">{errors.subscribe}</span>}
                      </div>
                    )
                  ))}

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className={`w-full bg-[#c9b38c] text-white py-3 hover:bg-indigo-700 transition duration-300 ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={isButtonDisabled}
                  >
                    {form_info?.submit_title}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ContactSection;
