import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
} from "@material-tailwind/react";
import logoa from "../../images/jrapics/jranewwhite.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function NavList({ data, setOpenNav }) {
  const navlist = data.main_menu ? data.main_menu : [];
  navlist.sort((a, b) => a.order - b.order);

  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      {/* Render all menu items */}
      {navlist.map((item) => (
        <Typography
          as={Link}
          to={item.path}
          variant="small"
          color="blue-gray"
          key={item.c_key}
          className="font-medium text-gray-100"
          onClick={() => setOpenNav(false)}
        >
          <ListItem className="flex gap-2 py-2">{item.title}</ListItem>
        </Typography>
      ))}
    </List>
  );
}

export default function Header({ data, serverurl }) {
  const [openNav, setOpenNav] = React.useState(false);
  const logo = serverurl + data?.heder_logo_image;
  const [isScrolling, setIsScrolling] = React.useState(false);
  const side_contact_us = data.side_contact_us ? data.side_contact_us : {};

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  React.useEffect(() => {
    function handleScroll() {
      setIsScrolling(window.scrollY > 0);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar
      fullWidth
      shadow={false}
      blurred={false}
      className={`fixed top-0 z-50 border-0 transition duration-300 ease-in-out ${
        isScrolling
          ? "bg-[#262B3E] opacity-100"
          : "lg:bg-transparent bg-[#262B3E] opacity-90"
      }`}
    >
      <div className="container mx-auto flex items-center justify-between text-blue-gray-100">
        <Link
          className="block antialiased font-sans text-white text-lg font-bold"
          to="/"
        >
          <img src={logoa} alt="Logo" />
        </Link>

        <div className="hidden lg:block">
          <NavList data={data} setOpenNav={setOpenNav} />
        </div>
        <div className="hidden gap-2 lg:flex">
          <Typography
            as={Link}
            to={side_contact_us.path}
            variant="small"
            color="blue-gray"
            className="font-medium text-gray-100"
          >
            <Button
              size="md"
              fullWidth
              className="bg-[#c9b38c] rounded-none hover:bg-[#b99c69]"
            >
              {side_contact_us.title}
            </Button>
          </Typography>
        </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon
              className="h-7 w-7 rounded-md m-0 text-white bg-[#c9b38c] hover:bg-[#b99c69]"
              strokeWidth={2}
            />
          ) : (
            <Bars3Icon
              className="h-7 w-7 rounded-md m-0 text-white bg-[#c9b38c] hover:bg-[#b99c69]"
              strokeWidth={2}
            />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList data={data} setOpenNav={setOpenNav} />
        <div className="w-full flex-nowrap items-center flex justify-center gap-2 lg:hidden">
          <Typography
            as={Link}
            to={side_contact_us.path}
            variant="small"
            color="blue-gray"
            className="font-thin text-gray-100"
            onClick={() => setOpenNav(false)}
          >
            <Button
              className="bg-[#c9b38c] rounded-none hover:bg-[#b99c69]"
              fullWidth
            >
              {side_contact_us.title}
            </Button>
          </Typography>
        </div>
      </Collapse>
    </Navbar>
  );
}
