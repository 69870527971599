import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import PaginationRounded from "../components/blog/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { callingSecureAPI } from '../components/Service/RequestService'
import { IconButton, Input, InputLabel, Modal, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Loader from '../components/common/Loader';

export default function MyBlogs({ handlerMenuLoad }) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageSize, setpPgeSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagename, setPagename] = useState("REVIEW");
  const [owner_id, setOwner_id] = useState(null);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [basepath, setBasepath] = useState("");
  const [category, setCategory] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [reviewBlog, setReviewBlog] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reviewComment, setReviewComment] = useState('');


  const no_image = "no_image"


  //Check user is login or not
  const isUserPrset = atob(localStorage.getItem("userLoginData"))
  let user = {};
  if (isUserPrset && isUserPrset.length > 100)
    user = JSON.parse(isUserPrset);
  else
    history.push('/wp-admin');


  //fetch Data from DB
  const fetchData = async () => {
    let owner_id;
    if (user) {
      owner_id = user?._id
      setOwner_id(owner_id);
    }
    try {
      let params = "?pageSize=" + pageSize + "&currentPage=" + currentPage + "&pagename=" + pagename;
      const response = await callingSecureAPI('/blog' + params, 'GET');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (response.status === 200) {
        const result = await response.json();
        setData(result?.data?.paginatedData);
        if (result.data) {
          setBasepath(result?.data?.base_path)
          let count = result?.data?.totalCount[0]?.total ? result?.data?.totalCount[0]?.total : 1
          count = Math.ceil(count / pageSize)
          setTotalPageCount(count)
          console.log(count, "here")
        }
        console.log(owner_id, "here1")
        console.log(result.data);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  //page data Change
  const handleChildCalls = async (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData();
  };


  const callingBlogDetails = async (blogId) => {
    setDataLoaded(false);
    if (!blogId) {
      toast("Blog ID is missing.");
      setDataLoaded(true);
      return;
    }

    setDataLoaded(true)
    const response = await callingSecureAPI(`/blog/${blogId}`, "GET");
    if (response.status === 200) {
      const responseData = await response.json();
      setReviewBlog(responseData?.data?.blog)
      console.log(
        responseData
      )
    }
    setDataLoaded(false)
  }

  const openNewTab = (blog, e) => {
    callingBlogDetails(blog?._id)
    setOpenModal(true)
  };

  const openEditMode = (blogId) => {
    handlerMenuLoad("Create New Blog")
    history.push('/wp-admin/dashboard', { blogId: blogId });
  }

  const openBlogInNewTab = (blogId, e) => {
    window.open('/read-blog?blogId=' + blogId, '_blank');
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };


  const handleCategory = async (category) => {
    if (category) {
      setCategory(category)
      setCurrentPage(1)
      fetchData("&category=" + category + "&currentPage=1")
    }
  }

  const getDate = (data, type) => {
    const date = new Date(data);
    let options = {};
    if (type === "Date")
      options["day"] = "2-digit"
    if (type === "Month")
      options["month"] = "long"
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }


  //Submit the publish state
  const submitReviewStatus = async (BlogId) => {
    const post = {
      _id: BlogId,
      published: true,
      reviewed_by: user?.name,
      reviewed: true
    }

    setDataLoaded(true)
    if (post._id) {
      const response = await callingSecureAPI("/blog", "POST", {}, post);
      if (response.ok) {
        toast("Blog Publish Successfully")
        setReviewBlog()
        setOpenModal(false)
        fetchData();
      }
      else {
        toast("Something Wrong")
      }
    }
    else {
      toast("Something Wrong, Please Reload Page")
    }
    setDataLoaded(false)
  }

  const getViewData = (publishDate, createdDate) => {
    const dateString = publishDate ? publishDate : createdDate;
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };



  //Comment Save in Review
  const saveCommentOnBlogReview = async (BlogId) => {
    const body = {
      _id: BlogId,
      reviewed_comment: {
        comm_name: user?.name,
        comment: reviewComment
      }
    }
    try {
      const response = await callingSecureAPI('/blog', 'POST', {}, body);
      const jsonResponse = response.json();
      if (response.status === 200) {
        toast("Comment Added Successfully")
      }
      else {
        toast(jsonResponse?.message || "SomeThing Wrong")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const saveDataComment = (e) => {
    console.log("Call")
    setReviewComment(e.target.value)
  }


  const GridCard = () => {
    return (
      <>
        <div className="max-w-screen-4xl mx-auto p-5 sm:p-10 md:p-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            {data.map((blog, index) => (
              <div className="rounded overflow-hidden shadow-lg">
                <div className="relative">
                  <a>
                    <img
                      className="w-full h-56 object-cover"
                      src={
                        basepath +
                        (blog.files.length > 0
                          ? blog.files[0]?.c_key
                          : no_image)
                      }
                      alt="Sunset in the mountains"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                  </a>
                  <a onClick={handleCategory.bind(null, blog.category[0] ? blog.category[0] : undefined)}>
                    <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                      {blog.category[0] ? blog.category[0] : "Not Define"}
                    </div>
                  </a>
                  <a>
                    <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                      <span className="font-bold">{getDate(blog?.created_date, "Date")}</span>
                      <small>{getDate(blog?.created_date, "Month")}</small>
                    </div>
                  </a>
                  <div className="absolute top-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                    {blog?.owner?.name}
                  </div>
                </div>
                <div className="px-6 py-4">
                  <a
                    className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
                  >
                    {truncateText(blog?.blog_header, 5)}
                  </a>
                  <p className="text-gray-500 text-sm">
                    {truncateText(blog?.description, 30)}
                  </p>
                </div>
                <div className="px-6 py-4 flex flex-row  mt-auto items-center">
                  <div className="flex justify-between gap-3">
                    <Button
                      variant='contained'
                      onClick={openNewTab.bind(null, blog)}
                      size="small"
                    >
                      {"Review"}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        {/* Review Modal */}
        {
          dataLoaded && <Loader />
        }
        {!dataLoaded &&
          <Modal
            open={openModal}
            onClose={() => setOpenModal(!openModal)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>

                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ display: "flex", justifyContent: "center" }}
                  className='text-center text-gray-700'>
                  Blog Preview
                </Typography>

                < IconButton onClick={() => setOpenModal(!openModal)} className='bg-blue-gray-300'>
                  <CloseIcon />
                </IconButton>
              </Box>

              <hr className='border-l-deep-orange-400' />
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, whiteSpace: "pre-wrap" }}
              >
                <strong>{reviewBlog?.blog_header}</strong> <br /> <br />
              </Typography>


              <hr className='border-l-deep-orange-400' />
              <Typography style={{ margin: 20 }}>
                By <strong>{reviewBlog?.users}</strong> | <strong>JRA Legal Solution</strong>{" "}
                <br /> {getViewData(reviewBlog?.created_date)}
              </Typography>

              <hr className='border-l-deep-orange-400' />
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, whiteSpace: "pre-wrap" }}
              >
                <div dangerouslySetInnerHTML={{ __html: reviewBlog?.blog_details }} />
              </Typography>

              { reviewBlog?.review_comment && reviewBlog?.review_comment?.length > 0 &&
                <>
                  <hr className='border-l-deep-orange-400 mt-10' />
                  <Typography
                    id="modal-modal-comment"
                    sx={{ mt: 2, whiteSpace: "pre-wrap" }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: reviewBlog?.review_comment }} />
                  </Typography>
                </>
              }


              {/* Add Comment Section */}
              {/* <Typography
                id="modal-modal-add-comment"
                sx={{ mt: 10 }}>
                <hr className='border-l-deep-orange-400'
                  sx={{ mt: 2, whiteSpace: "pre-wrap" }} />
                <InputLabel id="demo-simple-select-label" className='mt-2 text-gray-800'>Feedback Comment</InputLabel>
                <Input
                  minRows={3}
                  id="comment_review"
                  className="w-full border-cyan-900 mt-5"
                  placeholder="Type Here ......."
                  multiline
                  value={reviewComment}
                  onChange={saveDataComment}
                />
              </Typography> */}

              {/* Action Buttons */}
              <hr className='border-l-deep-orange-400' />
              <div className="flex flex-row mt-3 w-full">
                <div className="flex justify-end gap-3 w-full">
                  <Button variant='contained' onClick={() => setOpenModal(!openModal)} size="small">
                    Cancel
                  </Button>
                  <Button variant='contained' onClick={() => openBlogInNewTab(reviewBlog?._id)} size="small">
                    View as Blog Page
                  </Button>
                  {/* <Button variant='contained' onClick={() => saveCommentOnBlogReview(reviewBlog?._id)} size="small">
                    Add Comment
                  </Button> */}
                  <Button variant='contained' onClick={() => submitReviewStatus(reviewBlog?._id)} size="small">
                    Publish
                  </Button>
                </div>
              </div>

            </Box>
          </Modal>
        }

      </>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div> Results: {category ? category : "ALL"}</div>
        <GridCard />
        {loading && totalPageCount > 1 && <div style={{ marginTop: 20, float: "right", height: 100 }}><PaginationRounded count={totalPageCount} onClickCount={handleChildCalls} /></div>}
      </Box>
      <ToastContainer />
    </>
  );
}