import React, { useState } from "react";
import { Typography, Card, CardBody } from "@material-tailwind/react";
import { BiSquareRounded } from "react-icons/bi";

const JuristicFeatureSection = ({ data }) => {
  // Sort data based on order
  data.sort((a, b) => a.order - b.order);

  const [expanded, setExpanded] = useState({}); // Track which items are expanded

  const toggleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderContent = (item, index) => {
    const descriptionLength = item.description.replace(/<[^>]+>/g, "").length; // Strip HTML tags to count text length

    if (descriptionLength > 500) {
      return expanded[index] ? (
        <div dangerouslySetInnerHTML={{ __html: item.description }} />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: item.description.substring(0, 400) + "...",
          }}
        />
      );
    } else {
      return <div dangerouslySetInnerHTML={{ __html: item.description }} />;
    }
  };

  return (
    <section className="bg-gray-100 py-8 px-5 lg:px-10">
      <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap justify-center gap-6">
  {data.map((item, index) => (
    <div 
      key={index} 
      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4"
    >
      <Card className="transition-transform transform hover:scale-105 duration-300 ease-in-out cursor-pointer h-full">
        <CardBody className="p-6 flex flex-col justify-between h-full">
          <div className="flex items-center mb-4">
            <BiSquareRounded className="text-[#c9b38c] text-2xl mr-2" />
            <h3 className="text-lg font-semibold text-gray-800">
              {item.title}
            </h3>
          </div>
          <Typography className="text-gray-600 text-sm flex-grow">
            {renderContent(item, index)}
          </Typography>
          {item.description.replace(/<[^>]+>/g, "").length > 400 && (
            <span
              onClick={() => toggleExpand(index)}
              className="text-[#c9b38c] cursor-pointer mt-2 inline-block"
            >
              {expanded[index] ? "Read Less" : "Read More.."}
            </span>
          )}
        </CardBody>
      </Card>
    </div>
  ))}
</div>

      </div>
    </section>
  );
};

export default JuristicFeatureSection;
