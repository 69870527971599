import React, { useState, useEffect } from "react";
import ContactSection from "./ContactSection";
import MapSection from "./MapSection";
import PageHeader from "../common/pageHeader/PageHeader";
import { apiService } from "../common/apiService.js";
import Loader from "../common/Loader.jsx"
const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // New state for data loading
  const page_data = contactData?.page_data;
  const first_section_data = page_data?.first_section;


  //api calling
  const handleSubmit = async () => {
    try {
      const response = await apiService({
        path: "/page/contact_us_page",
        method: "GET"
      });
      const responseData = await response.json();
      setContactData(responseData.data);
      setDataLoaded(true); // Set dataLoaded to true after fetching data
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);


  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && <PageHeader
        backgroundImageUrl={first_section_data?.bg_image}
        title={first_section_data?.title}
        description={first_section_data?.description}
      />}
      {dataLoaded && <ContactSection contact_info={page_data?.firm_contact_info} form_info={page_data?.contect_form} />}
      {dataLoaded && <MapSection data={page_data?.map_section} />}
    </>
  );
};

export default Contact;
