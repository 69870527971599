import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import "../home/recent/recent.css";
import PageHeader from "../common/pageHeader/PageHeader";
import BlogSection from "./BlogSection";
import BlogData from '../Blog.json';
import { useLocation } from 'react-router-dom';
import {apiService, base_image_url} from "../common/apiService.js";
import Loader from "../common/Loader.jsx"

const Blog = () => {
  const location = useLocation();
  const params = location.state?.params;
  let pageData = BlogData?.page_data ? BlogData?.page_data : {};
  const [blogList, setBlogList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagename] = useState("All");
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [basepath, setBasepath] = useState(base_image_url);

  //fetching blog list view
  const fetchData =async (etcPrams, pageCount) => {
      try {
        let params = "?pageSize=" + pageSize + "&currentPage=" + (pageCount ? pageCount : currentPage) + "&pagename=" + pagename ;
        const response = await apiService({
          path: "/blog" + params + (etcPrams),
          method: "GET"
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if (response.status === 200) {
          const result = await response.json();
          setBlogList(result?.data?.paginatedData);
          if (result.data) {
            let count = result?.data?.totalCount[0]?.total ? result?.data?.totalCount[0]?.total : 1;
            count = Math.ceil(count / pageSize);
            setTotalPageCount(count);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoaded(true);
      }
    }

  const updateBlogRecords =async (count) => {
    fetchData("",count);
  };

  const refreshPage = async(params) => {
    fetchData(params,1);
  };

  useEffect(() => {
    console.log(params,"here")
    if(params)
      fetchData(params,1);
    else
      fetchData("",1);
  }, []);

  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && (
        <PageHeader
          backgroundImageUrl={pageData?.backgroundImageUrl}
          title={pageData?.page_title}
          description={pageData?.page_discription}
        />
      )}

      {dataLoaded && (
        <BlogSection 
          updateBlogRecords={updateBlogRecords} 
          totalPageCount={totalPageCount} 
          base_path={basepath} 
          blogs={blogList}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default Blog;
