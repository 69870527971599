import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation , useHistory } from "react-router-dom";
import Header from "../common/header/Header";
import Home from "../home/Home";
import Footer from "../common/footer/Footer";
import Blog from "../blog/Blog";
import Modal from "react-modal";
import { Button } from "@material-tailwind/react";
import { Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import Teams from "../teams/Teams";
import Media from "../media/Media";
import Career from "../career/Career";
import Contact from "../contact/Contact";
import adminlogin from "../../admin/adminLogin/Adminlogin.jsx";
import adminDashboard from '../../admin/PanelDashboard.js'
import Privacy_policy from "../term_policy/Privacy_policy.jsx";
import Services from "../services/Services.jsx";
import AboutUs from "../aboutus/AboutUs.jsx";
import TermsAndConditions from "../term_policy/TermsAndConditions.jsx";
import {apiService} from "../common/apiService.js";
import SinglBlogSection from '../blog/SinglBlogSection.jsx'
import WhatsAppWidget from "../Widget/WhatsAppWidget.jsx";
import Faq from "../term_policy/Faq.jsx";
import Cookie from "../term_policy/Cookie.jsx";

Modal.setAppElement("#root");

const Pages = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(false);
  const [headerFooterData, setHeaderFooterData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // New state for data loading

  const handleSubmit = async () => {
    try {
      const responseData = await apiService({
        path: '/page/header_footer',
        method: 'GET',
      });
      const response = await responseData.json();
      setHeaderFooterData(response?.data);
      setDataLoaded(true); // Set dataLoaded to true after fetching data
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    setOpen(true);
    handleSubmit();
  }, []);

  let isWPAdmin = location.pathname.startsWith("/wp-admin");
  if(!isWPAdmin){
    //localStorage.clear();
  }

  const isUserPrset = atob(localStorage.getItem("userLoginData"))
  let adminLogin = false
  if (isUserPrset && isUserPrset.length > 50 )
  {
    adminLogin = true;
    let pageName = location.pathname.startsWith("/read-blog")
    const blogId = queryParams.get('blogId')
    if(pageName && blogId && blogId.length > 20)
      history.push('/read-blog', {blogId: queryParams.get('blogId')});
  }
   

  const handleOpen = () => {
    setOpen(!open);
    saveConsnet();
  };

  const saveConsnet = async() => {
    console.log("Calling")
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      await apiService({
        path: '/consent',
        method: 'POST',
        body: {
          disclaimer_consent : true,
          ip_address: data?.ip,
          user_agent: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language,
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className="pages-container font-roboto">
      {/* Render dialog only if 'open' state is true */}
      {open && !isWPAdmin && !adminLogin && (
       <Dialog
       open={open}
       handler={handleOpen}
       dismiss={{ outsidePress: false, enabled: true, escapeKey: false }}
       className="shadow-none max-h-[90vh] overflow-y-auto" // Ensure the dialog doesn't exceed the viewport height
     >
       <DialogHeader className="flex justify-center gap-7 pt-2">DISCLAIMER</DialogHeader>
       <hr className="mt-2 mb-2 max-w-full" />
       
       {/* Make DialogBody scrollable */}
       <DialogBody className="text-justify leading-none text-sm overflow-y-auto max-h-[60vh] px-4">
         The Bar Council of India does not permit advertisement or solicitation by advocates in any form
         or manner. By accessing this website, <span className="font-bold">www.JRAlegalsolutions.com</span>,
         you acknowledge and confirm that you are seeking information relating to <span className="font-bold">
         JRA Legal Solutions</span> of your own accord and that there has been no form of solicitation, 
         advertisement, or inducement by <span className="font-bold">JRA Legal Solutions</span> or its members.
         The content of this website is for informational purposes only and should not be interpreted as 
         soliciting or advertisement. No material/information provided on this website should be construed as 
         legal advice. <span className="font-bold">JRA Legal Solutions</span> shall not be liable for consequences 
         of any action taken by relying on the material/information provided on this website. The contents of 
         this website are the intellectual property of <span className="font-bold">JRA Legal Solutions</span>.
       </DialogBody>
       
       <hr className="mt-2 mb-2 max-w-full" />
       
       <DialogFooter className="flex justify-center gap-7 pt-2">
         <div>
           <Button
             className="shadow-none rounded-none border-none focus:border-none focus-visible:outline-none bg-[#c9b38c] text-[#fff]"
             onClick={handleOpen}
           >
             <span>I Agree</span>
           </Button>
         </div>
       </DialogFooter>
     </Dialog>
     
      )}

      <Router>
        {dataLoaded &&  !isWPAdmin && <WhatsAppWidget /> } {/* Render Header only if not on /wp-admin */}
        {dataLoaded &&  !isWPAdmin && <Header serverurl= {headerFooterData?.base_path} data={headerFooterData.header} />} {/* Render Header only if not on /wp-admin */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/aboutUs" component={AboutUs} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/read-blog" component={SinglBlogSection} />
          <Route exact path="/blog/:blogId" component={Blog} />
          <Route exact path="/Teams" component={Teams} />
          <Route exact path="/Media" component={Media} />
          <Route exact path="/Services" component={Services} />
          <Route exact path="/Career" component={Career} />
          <Route exact path="/Contact" component={Contact} />
          <Route exact path="/wp-admin" component={adminlogin} />
          <Route exact path="/Privacy_policy" component={Privacy_policy} />
          <Route exact path="/TermsAndConditions" component={TermsAndConditions} />
          <Route exact path="/Cookie" component={Cookie} />
          <Route exact path="/Faq" component={Faq} />
          <Route exact path="/wp-admin/dashboard" component={adminDashboard} />
        </Switch>
        {dataLoaded && !isWPAdmin && <WhatsAppWidget />} 
        {dataLoaded && !isWPAdmin && <Footer serverurl= {headerFooterData.base_path} data={headerFooterData.footer} />} 
      </Router>
    </div>
  );
};

export default Pages;
