import React from "react";
import { IoHomeOutline } from "react-icons/io5";
import { Typography } from "@material-tailwind/react";
import Zoom from 'react-medium-image-zoom'; // Import Zoom component
import 'react-medium-image-zoom/dist/styles.css'; // Import styles for zoom

export function Recent({ recent, serverUrl }) {
  return (
    <>
      <section className="lg:py-8 md:py-5 py-5 px-5 lg:px-10">
        <div className="flex">
          <div className="mx-auto">
            <div className="mb-8 text-center lg:mb-8">
              <Typography className="flex text-[#fff] justify-center mb-3">
                <IoHomeOutline className="text-4xl bg-[#c9b38c] shadow-md rounded-full p-1" />
              </Typography>
              <Typography
                color="blue-gray"
                className="text-lg text-md text-[#c9b38c] ml-2"
              >
                {recent[0].about_string_1}
              </Typography>
              <Typography
                variant="h1"
                color="blue-gray"
                className="my-2 !text-2xl lg:!text-4xl"
              >
              </Typography>
              <Typography
                variant="lead"
                className="mx-auto w-full text !text-gray-500 max-w-10xl block antialiased font-sans text-sm xl:text-xl text-justify lg:text-xl md:text-xl font-normal leading-relaxed text-inherit max-w-10xl"
              >
                {recent[0].about_string_2}
              </Typography>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-10 lg:gap-10 md:px-6 align-middle">
              {/* Image Section with Zoom */}
              <div className="flex justify-center items-center">
                <div className="overflow-hidden rounded-full max-w-xl w-full shadow-lg">
                  <Zoom>
                    <img
                      src={serverUrl + recent[1].home_recent_about}
                      alt="About Us"
                      className="w-full h-auto object-cover"
                    />
                  </Zoom>
                </div>
              </div>

              {/* Text Section */}
              <div className="flex flex-col justify-center">
                <Typography
                  variant="h2"
                  className="text-gray-900 font-bold text-2xl lg:text-3xl text-center lg:text-left"
                >
                  {recent[2].first_letter}
                </Typography>
                <p className="text-sm sm:text-base lg:text-lg text-gray-700 mt-4 text-justify">
                  {recent[2].quotes}
                </p>
                <div className="mt-4 text-center lg:text-left">
                  <Typography className="text-xl font-semibold text-gray-800">
                    {recent[2].name}
                  </Typography>
                  <Typography className="text-gray-600">
                    {recent[2].title}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Recent;
