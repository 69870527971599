import React, { useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SaveIcon from "@mui/icons-material/Save";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MyDetails from "./MyDetails";
import MyBlogs from "./MyBlogs.js";
import MyApprovals from "./MyApprovals.js";
import AllBlogs from "./AllBlogs.js";
import MySavedBlogs from "./MySavedBlogs.js";
import CreateEditBlog from "./CreateEditBlog.js";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ContactUs from "./ContactUs.js";
import CareerQueries from "./CareerQueries.js";
import SubcriberView from "./SubcriberView.js";
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IoCopy } from "react-icons/io5";
import { APP_BASE_URL, callingSecureAPI } from '../components/Service/RequestService'
import NewLetter from "./NewLetter.js";
import Whatsapp from "./Whatsapp.js";

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PanelDashboard() {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const blogId = location.state?.blogId;
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(!isMobile);
  const [currentPage, setCurrentPage] = useState("my_details");
  const [imageList, setImageList] = useState([]);
  const [showImageList, setShowImageList] = useState(false);
  const [copiedPath, setCopiedPath] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [menuList, setMenuList] = useState([{
    "order": 20,
    "section": "C",
    "title": "Log Out",
    "c_key": "log_out"
  }]);


  //Check user is login or not
  const isUserPrset = atob(localStorage.getItem("userLoginData"))
  let user = {};
  if (isUserPrset && isUserPrset.length > 100)
    user = JSON.parse(isUserPrset);
  else
    history.push('/wp-admin');

  console.log("menu",blogId,menuList,user, currentPage)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlerMenuLoad = (page, isClick = false) => {
    if (page === "log_out") {
      localStorage.clear();
      history.push("/wp-admin");
      return;
    }
    if (isClick)
      setIsClick(true)
    else
      setIsClick(false)
    setCurrentPage(page);
    if (isMobile) handleDrawerClose();
  };

  const handleChildData = async (isTrue, postId) => {
    if (isTrue) {
      setIsLoading(false);
      setShowImageList(true);
      setTimeout(() => callImageList(postId), 100);
    } else {
      setIsLoading(true);
      setImageList([]);
      setShowImageList(false);
    }
  };

  const callImageList = async (postId) => {
    try {
      const response = await callingSecureAPI(`/image/${postId}`, 'GET');
      if (response.status === 200) {
        const responseData = await response.json();
        setImageList(responseData.data);
        setIsLoading(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(true);
      toast("Something went wrong, try again later");
    }
  };

  const copyPathToClipboard = (item) => {
    const path = item.item.base_path;
    navigator.clipboard.writeText(path)
      .then(() => {
        setCopiedPath(path);
        setTimeout(() => {
          setCopiedPath(null);
        }, 2000);
      })
      .catch((error) => {
        toast("Something went wrong during copying the path");
        console.error("Error copying path to clipboard:", error);
      });
  };

  useEffect(() => {
    const user = JSON.parse(atob(localStorage.getItem("userLoginData")));
    if (!user) {
      history.push("/wp-admin");
    }
    setMenuList(user?.permissions)
  }, []);

  const drawer = (

    <div className=" bg-[#fff] menu?.c_key-[#A69FD8]">
      <List className="pt-0 mt-0" >
        {menuList?.filter(item => item?.section === "A")?.map((menu, index) => (
          <ListItem key={menu?.c_key} disablePadding >
            <ListItemButton
              onClick={() => handlerMenuLoad(menu?.c_key, true)}
              sx={{
                backgroundColor: currentPage === menu?.c_key ? '#6558D5' : 'transparent',
                '&:hover': {
                  backgroundColor: currentPage === menu?.c_key ? '#6558D5' : '#F1EEFF',
                },
                color: currentPage === menu?.c_key ? 'white' : 'inherit',
              }}
            >
              <ListItemIcon sx={{ color: currentPage === menu?.c_key ? 'white' : '#A69FD8' }}>
                {index === 0 ? (
                  <AccountCircleIcon />
                ) : index === 3 ? (
                  <SaveIcon />
                ) : index === 4 ? (
                  <CreateNewFolderIcon />
                ) : (
                  <AllInboxIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={menu?.title} sx={{ color: currentPage === menu?.c_key ? 'white' : 'inherit' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuList?.filter(item => item?.section === "B")?.map((menu, index) => (
          <ListItem key={menu?.c_key} disablePadding>
            <ListItemButton
              onClick={() => handlerMenuLoad(menu?.c_key)}
              sx={{
                backgroundColor: currentPage === menu?.c_key ? '#6558D5' : 'transparent',
                '&:hover': {
                  backgroundColor: currentPage === menu?.c_key ? '#6558D5' : '#F1EEFF',
                },
                color: currentPage === menu?.c_key ? 'white' : 'inherit',
              }}
            >
              <ListItemIcon sx={{ color: currentPage === menu?.c_key ? 'white' : '#A69FD8' }}>
                <AllInboxIcon />
              </ListItemIcon>
              <ListItemText primary={menu?.title} sx={{ color: currentPage === menu?.c_key ? 'white' : 'inherit' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuList?.filter(item => item?.section === "C")?.map((menu, index) => (
          <ListItem key={menu?.c_key} disablePadding>
            <ListItemButton
              onClick={() => handlerMenuLoad(menu?.c_key)}
              sx={{
                backgroundColor: currentPage === menu?.c_key ? '#6558D5' : 'transparent',
                '&:hover': {
                  backgroundColor: currentPage === menu?.c_key ? '#6558D5' : '#F1EEFF',
                },
                color: currentPage === menu?.c_key ? 'white' : 'inherit',
              }}
            >
              <ListItemIcon sx={{ color: currentPage === menu?.c_key ? 'white' : '#A69FD8' }}>
                <AllInboxIcon />
              </ListItemIcon>
              <ListItemText primary={menu?.title} sx={{ color: currentPage === menu?.c_key ? 'white' : 'inherit' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      {showImageList && imageList.length > 0 && (
        <div>
          <div className="image-list" style={{ margin: 20 }}>
            <p><strong>All Images For This Blog:</strong></p>
            {imageList.map((item, index) => (
              <div key={item._id} className="image-item" style={{ height: 120, width: 150 }}>
                <div>
                  <img src={item.base_path} alt="person" />
                  <button style={{ padding: 10 }} onClick={() => copyPathToClipboard({ item })}><IoCopy /></button>
                  {copiedPath === item.base_path ? <span>Copied!</span> : <span>Copy Path</span>}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {showImageList && imageList.length === 0 && isLoading && (
        <div><span>No Images Found Related To This Blog</span></div>
      )}
    </div>

  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: '#6558D5' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {menuList?.filter(item=> item.c_key == currentPage)[0]?.title || "My Details"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="bg-[#6558D5]">
          <IconButton onClick={handleDrawerClose}>
            <div className="menu?.c_key-2xl  menu?.c_key-white pr-2">
              Admin Panel
            </div>
            {theme.direction === 'ltr' ? <ChevronLeftIcon className=" menu?.c_key-[#6558D5] bg-white rounded-full p-" /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        {drawer}
      </Drawer>
      <Main open={open} sx={{ bgcolor: "#F1EEFF" }}>
        <DrawerHeader />
        {currentPage === "my_details" ? (
          <MyDetails />
        ) : currentPage === "my_blogs" ? (
          <MyBlogs handlerMenuLoad={handlerMenuLoad} />
        ) : currentPage === "all_blogs" ? (
          <AllBlogs handlerMenuLoad={handlerMenuLoad} />
        ) : currentPage === "my_saved_blogs" ? (
          <MySavedBlogs handlerMenuLoad={handlerMenuLoad} />
        ) : currentPage === "my_appovals" ? (
          <MyApprovals handlerMenuLoad={handlerMenuLoad} />
        ) : currentPage === "whatsapp" ? (
          <Whatsapp handlerMenuLoad={handlerMenuLoad} />
        ) : currentPage === "newsletter" ? (
          <NewLetter handlerMenuLoad={handlerMenuLoad} />
        ) : currentPage === "create_blogs" ? (
          <CreateEditBlog isShowImageList={handleChildData} blogId={isClick ? undefined : blogId} />
        ) : currentPage === "contact_us" ? (
          <ContactUs />
        ) : currentPage === "career_page" ? (
          <CareerQueries />
        ) : currentPage === "subscriber_list" ? (
          <SubcriberView />
        ) : ("No Menu Available or Connect with Admin"
        )}
      </Main>
    </Box>
  );
}
