import React, { useState, useEffect } from "react";
import PageHeader from "../common/pageHeader/PageHeader";
import { apiService, base_image_url } from "../common/apiService";
import dataJson from "../Teams.json";
import Loader from "../common/Loader";
import { Dialog } from "@material-tailwind/react";
import { IoIosCloseCircle } from "react-icons/io";
import { RxLinkedinLogo } from "react-icons/rx";
import defaultUser from "../images/jra/userdefault.jpg";

const DUMMY_USER_IMAGE = defaultUser;

const Teams = () => {
  const [pageData, setPageData] = useState(null);
  const [basePath, setBasePath] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const fetchPageData = async () => {
    try {
      const response = await apiService({
        path: "/page/teams_page",
        method: "GET",
      });
      const responseData = await response.json();
      setPageData(responseData?.data?.page_data || null);
      setBasePath(responseData?.data?.page_data?.base_path || null);
      setIsDataLoaded(true);
    } catch (error) {
      console.error(
        "Failed to fetch page data. Falling back to local data.",
        error
      );
      setPageData(dataJson?.page_data || null);
      setIsDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  const TeamMemberCard = ({ employee }) => {
    const {
      first_name: firstName,
      last_name: lastName,
      designation,
      image,
    } = employee;

    const openModal = (item) => {
      setSelectedItem(item);
      setIsModalOpen(true);
    };

    return (
      <>
        <div
          className="bg-white p-3 shadow-lg rounded-xl hover:scale-105 transition-transform duration-300 cursor-pointer"
          onClick={() => openModal(employee)}
        >
          <img
            src={image ? `${base_image_url}${image}` : DUMMY_USER_IMAGE}
            alt={`${firstName || "User"}`}
            className="w-44 h-44 mx-auto rounded-full object-cover object-top"
          />
          <div className="mt-6 text-center">
            <h4 className="text-gray-900 text-lg font-semibold">
              {firstName} {lastName}
            </h4>
            <p className="text-gray-500 text-sm mt-1">{designation}</p>
          </div>
        </div>
      </>
    );
  };
  const AssociateCard = ({ employee }) => {
    const {
      first_name: firstName,
      last_name: lastName,
      designation,
      image,
    } = employee;

    // const openModal = (item) => {
    //   setSelectedItem(item);
    //   setIsModalOpen(true);
    // };

    return (
      <>
        <div className="bg-white p-3 shadow-lg rounded-xl  ">
          <img
            src={image ? `${base_image_url}${image}` : DUMMY_USER_IMAGE}
            alt={`${firstName || "User"}`}
            className="w-44 h-44 mx-auto rounded-full object-cover object-top"
          />
          <div className="mt-6 text-center">
            <h4 className="text-gray-900 text-lg font-semibold">
              {firstName} {lastName}
            </h4>
            <p className="text-gray-500 text-sm mt-1">{designation}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!isDataLoaded && <Loader />}

      {isDataLoaded && (
        <>
          <PageHeader
            backgroundImageUrl={pageData?.backgroundImageUrl}
            title={pageData?.page_title}
            description={pageData?.page_discription}
          />

          <div className="font-[sans-serif] bg-gray-50 p-4">
            <div className="container mx-auto">
              <div className="text-center mb-8">
                <span className="text-sm text-[#c9b38c] font-bold">
                  QUALIFIED ATTORNEYS
                </span>
                <h2 className="text-2xl md:text-4xl lg:text-4xl font-normal text-gray-900 mt-2">
                  {pageData?.team?.title}
                </h2>
                <p className="text-lg text-gray-700 mt-4">
                  {pageData?.team?.description}
                </p>
              </div>

              <div className="flex flex-wrap justify-center gap-8 text-center mt-12">
                {pageData?.team?.employees?.map((employee, index) => (
                  <div
                    key={index}
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                  >
                    <TeamMemberCard employee={employee} />
                  </div>
                ))}
              </div>

              <div className="text-center my-8">
                <h2 className="text-2xl md:text-4xl lg:text-4xl font-normal text-gray-900 mt-2">
                  {pageData?.associate_professional?.title}
                </h2>
                <p className="text-lg text-gray-700 mt-4">
                  {pageData?.associate_professional?.description}
                </p>
              </div>
      
              <div className="flex flex-wrap justify-center gap-8 text-center mt-12">
                {pageData?.associate_professional?.employees?.map((employee, index) => (
                  <div
                    key={index}
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                  >
                    <AssociateCard employee={employee} />
                  </div>
                ))}
              </div>
            </div>

            {selectedItem && (
              <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="bg-gray-200 dark:bg-gray-800 flex flex-wrap items-center w-full overflow-hidden">
                  <div className="container bg-white shadow-lg transform duration-200 ease-in-out">
                    <div className="h-32 overflow-hidden">
                      {/* <img
                        className="w-full object-cover object-top"
                        src={selectedItem.image ? `${base_image_url}${selectedItem.image}` : DUMMY_USER_IMAGE}
                        alt={selectedItem.first_name}
                      /> */}
                      <img
                        className="w-full object-cover object-top-100px"
                        src="https://images.unsplash.com/photo-1589829545856-d10d557cf95f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="header img"
                      />
                    </div>
                    <div className="flex justify-center px-5 -mt-12">
                      <img
                        className="h-36 w-36 bg-white p-2 rounded-full object-cover object-top"
                        src={
                          selectedItem.image
                            ? `${base_image_url}${selectedItem.image}`
                            : DUMMY_USER_IMAGE
                        }
                        alt={selectedItem.first_name}
                      />
                    </div>
                    <div className="absolute p-1 top-0 right-0">
                      <div onClick={() => setIsModalOpen(false)}>
                        <IoIosCloseCircle className="text-4xl text-[#dfdfdf] shadow-2xl hover:text-[#fff] cursor-pointer duration-75 transition-all" />
                      </div>
                    </div>
                    <div className="text-center px-6">
                      <h2 className="text-gray-800 text-3xl font-bold">
                        {selectedItem.first_name} {selectedItem.last_name}
                      </h2>
                      <div className="flex justify-center items-center gap-2">
                        <p className="text-lg font-bold">
                          {selectedItem.designation}
                        </p>

                        {selectedItem.linkdin && (
                          <a
                            className="text-gray-400 hover:text-blue-500 flex items-center"
                            href={selectedItem.linkdin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <RxLinkedinLogo className="text-[#0077B7] hover:text-[#0063C2] text-2xl transition-all" />
                          </a>
                        )}
                      </div>

                      <p className="mt-2 text-gray-700 text-sm font-bold text-justify overflow-y-auto max-h-[16vh] transition-all">
                        {selectedItem.profile_summary}
                      </p>
                      <p className="text-gray-500 text-sm mt-2 text-justify">
                        {selectedItem.bio}
                      </p>
                    </div>
                    <hr className="mt-6" />
                  </div>
                </div>
              </Dialog>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Teams;
