import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import "./joditeditor.css";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_BASE_URL, callingSecureAPI } from '../components/Service/RequestService'
import { useHistory } from 'react-router-dom';

import JoditEditor from "jodit-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Input } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const buttonValue = {
  save: "save_blog",
  publish: "publish_blog",
  delete: "delete_blog"
}

const defaultTheme = createTheme();

export default function CreatePost({ isShowImageList, blogId }) {
  const history = useHistory();
  const isUserPrset = atob(localStorage.getItem("userLoginData"))
  let user = {};
  if (isUserPrset && isUserPrset.length > 100)
    user = JSON.parse(isUserPrset);
  else
    history.push('/wp-admin');

  console.log("user", user)
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(["Select an Options"]);
  const [previewContent, setPreviewContent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [taglist, setTaglist] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [showhide, setShowhide] = useState(false);
  const [createdUser, setCreatedUser] = useState("");
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const [newCategory, setNewCategory] = useState();


  const [postId, setPostId] = useState("");
  const [imageList, setImageList] = useState(null);

  const editor = useRef(null);
  const [post, setPost] = useState({
    blog_details: "",
    is_saved: false,
  });

  const fieldChanged = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
  };
  const contentFieldChanged = (data) => {
    setPost({ ...post, blog_details: data });
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);


  //show uploaded images for this post
  const showUploadedList = () => {
    if (!postId || postId.length <= 3) {
      toast("You did not save this post and images");
      return
    }
    else {
      isShowImageList(!showImage, postId);
      setShowImage(!showImage)
    }
  }


  //validate post title
  const validateHeader = (process) => {
    if (process === buttonValue.delete)
      return true
    if (!header || header.length === 0) {
      toast("Title cannot be empty");
      return false;
    }
    else if (header.length <= 10) {
      toast("Please enter a Blog Title of at least 10 characters")
      return false;
    }
    return true;
  }

  //save blog data
  const handleChangeToSaveBlog = async (process, e) => {
    e.preventDefault();
    const isTitle = validateHeader(process);


    post["blog_header"] = header;
    post["description"] = description;
    post["category"] =  category.includes("Select an Options") ? [] : category
    post["tag_list"] = taglist;

    if (user) {
      post["owner_id"] = user?._id;
    }
    else {
      toast("Something wrong with login user, try later or re-login")
      return false;
    }
    if (isTitle) {
      //create case body
      if ((process === buttonValue.save) && postId?.length > 3 && !post?.owner_id) {
        toast("Something wrong user id not found, try later")
        return false;
      }

      //update case body
      if ((process === buttonValue.publish)) {

        if (!post.description || post.description.length < 1) {
          toast("Blog description cannot be empty")
          return false;
        }
        else if (post.description.length <= 100) {
          toast("Blog description should be at least 150 characters")
          return false;
        }

        console.log("post.category",post.category)
        if (post.category.includes("Select an Options") || post.category.length < 1) {
          toast("Blog catogory cannot be empty")
          return false;
        }

        //check required values
        if (!post.blog_details || post.blog_details.length < 1) {
          toast("Blog Details cannot be empty")
          return false;
        }
        else if (post.blog_details.length <= 200) {
          toast("Blog Details should be at least 200 characters")
          return false;
        }
        post["is_final"] = true

        //no need to review for super admin
        if(user?.position === 'super_admin'){
          post["published"] = true
          post["reviewed_submitted"] = true
          post["reviewed_by"] = user?.name
          post["reviewed"] = true
        }
        else {
          post["reviewed_submitted"] = true
        }

      }
      if ((process === buttonValue.delete) && postId?.length > 3 && !post?._id) {
        toast("Your Blog have some problem or not save yet")
        return false;
      }
      else if ((process === buttonValue.delete)) {
        post["is_deleted"] = true
      }

      //save blog stage to DB
      try {
        const response = await callingSecureAPI('/blog', 'POST', {}, post);
        if (response.status === 200) {
          if (process === buttonValue.save) {
            toast("Blog Is Saved Successfully");
            const result = await response.json();
            setPost(result.data);
            setPostId(result.data._id);
            setShowImage(!showImage)
          }
          else if (process === buttonValue.publish) {
            if(user?.position === 'super_admin')
              toast("Blog is Published Succcessfully")
            else 
              toast("Blog is Submitted For Review Succcessfully")
            setHeader('')
            setPost({ blog_details: "", is_saved: false })
            setDescription('')
            setTaglist('')
            setCategory(["Select an Options"])
            setPostId("")
          }
          else if (process === buttonValue.delete) {
            toast("Blog us deleted succesfully")
            setHeader('')
            setPost({ blog_details: "", is_saved: false })
            setDescription('')
            setTaglist('')
            setCategory(["Select an Options"])
            setPostId("")
          }

        } else {
          toast("Something went wrong");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.log("Form Fields Contain errors");
    }
  };

  const imageChangeHandle = async (event) => {
    const file = event.target.files[0]
    setImageName(file.name);
    setImageList(event.target.files[0])
  };

  //show hide uploader
  const showHideUploder = () => {
    setShowhide(!showhide)
  }

  ///upload image to DB
  const uploadImageHandler = async (e) => {
    e.preventDefault();
    if (postId && postId.length > 0) {
      if (!imageList)
        toast("No Images Selected, Please Select Atleast One Image")

      //create a form data body
      const formData = new FormData();
      formData.append("files", imageList);
      formData.append("blog_id", postId);

      try {
        const response = await callingSecureAPI('/fileimage', 'POST', {}, formData);
        if (response.status === 200) {
          toast("Images Uploaded SuccessFully");
          setImageList(null)
          setImageName(null)
        }
        //isShowImageList(showImage, postId);
      } catch (error) {
        toast("Something went wrong, try later");
        console.error("Error:", error);
      }
    }
  };

  const getCategoryList = async () => {
    try {
      const response = await callingSecureAPI('/utility/jre_blog_category_list', 'GET');
      if (response.status === 200) {
        const responseData = await response.json();
        setCategoryList(responseData?.data?.data_source || []);
        setDataLoaded(true);
      }
    } catch (error) {
      setCategoryList([{ order: 1, name: "GST" }, { order: 1, name: "Legal Advice 1" }]);
      console.error("Error:", error);
      setDataLoaded(true);
    }
  };

  //get blog for edit
  const getBlogDataForEdit = async (blogId) => {
    setDataLoaded(false);
    try {
      const response = await callingSecureAPI("/blog/" + blogId, "GET");
      if (response.status === 200) {
        const responseData = await response.json();
        setHeader(responseData?.data?.blog?.blog_header)
        setPost({ blog_details: responseData?.data?.blog?.blog_details, is_saved: responseData?.data?.blog?.is_saved, _id: responseData?.data?.blog?._id})
        setDescription(responseData?.data?.blog?.description)
        setTaglist(responseData?.data?.blog?.tag_list)
        setCategory(responseData?.data?.blog?.category || ["Select an Options"])
        setPostId(responseData?.data?.blog?._id)
        setIsPublished(responseData?.data?.blog?.published)
        setCreatedUser(responseData?.data?.blog?.users)
        setDataLoaded(true);
      }
    } catch (error) {
      setCategoryList([{ order: 1, name: "GST" }, { order: 1, name: "Legal Advice 1" }]);
      console.error("Error:", error);
      setDataLoaded(true);
    }
  }

  useEffect(() => {
    if (user) {
      setCreatedUser(user?.name)
    }
    getCategoryList();
    if (blogId && blogId.length > 5)
      getBlogDataForEdit(blogId);
  }, []);


  const handleChange1 = (event) => {
    const { value } = event.target;
    let newValue;
    if (value.length > 1 && value.includes("Select an Options"))
      newValue = value.slice(1)
    else
      newValue = value
    if (value.length === 0)
      newValue = ["Select an Options"]
    setCategory(newValue);
  };

  const handlePreview = () => {
    setPreviewContent(
      `Blog Title: ${header}\n\nBlog Description: ${description}\n\nBlog Details:\n${post.blog_details}\n\nSelected Categories: ${category.join(", ")}`
    );
    handleOpenModal();
  };

  const clearImage = () => {
    setImageName(null)
    setImageList(null)
  }

  const getDate = () => {
    const date = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(date);
    return formattedDate;
  }

  const handleSubmitOfFormModal = async () => {
    const body = {
      c_key: "jre_blog_category_list",
      data: {
        name: newCategory
      }
    }
    try {
      const response = await callingSecureAPI('/utility/blogcategory', 'POST', {}, body);
      const jsonResponse = response.json();
      if (response.status === 200) {
        setNewCategory()
        toast("Category Added Successfully")
        setOpenModalCategory(false)
        getCategoryList()
      }
      else {
        toast(jsonResponse?.message || "SomeThing Wrong")
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handlePaste = (event) => {
    console.log('Paste event detected:', event);
    // Further handling if necessary
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <div>
          <Typography component="h1" variant="h5">
            Blog Create / Update Form
          </Typography>
          <form noValidate style={{ marginTop: 10 }}>

            <InputLabel id="demo-simple-select-label" style={{ marginTop: 20, paddingLeft: 10 }}>Blog Title *</InputLabel>
            <input
              key="BlogTitle"
              type="text"
              id="header"
              className="w-full p-3 border border-gray-300 rounded-md"
              placeholder="header"
              aria-label="header"
              name="header"
              onChange={(e) => setHeader(e.target.value)}
              value={header}
              autoFocus
            />

            {!showhide && postId && postId.length > 3 && (
              <div>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span className="uppercase tracking-wide text-xs font-bold">
                          {imageName ? "Change Image" : "Select Image"}
                        </span>
                        <input
                          onChange={imageChangeHandle}
                          accept="image/*"
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                        />
                      </label>
                      {!imageName && <p className="pl-1">or drag and drop Image</p>}
                    </div>
                    {!imageName && <p className="text-xs leading-5 text-gray-600">
                      up to 2MB
                      {/* (Select 1 file at a time) */}
                    </p>}
                    <p className="text-xs ustify-center leading-5 text-gray-600">
                      {imageName ? ("Selected Image: " + imageName) : ("")} {imageName && <span onClick={clearImage} style={{ padding: 20, color: "blue" }}>Clear Image</span>}
                    </p>
                  </div>
                </div>
                {
                  <Button
                    variant="contained"
                    onClick={showHideUploder}
                    style={{ marginTop: 20 }}
                  >
                    Hide Uploader
                  </Button>
                }

                <Button
                  variant="contained"
                  onClick={uploadImageHandler}
                  style={{ marginTop: 20, float: "right" }}
                >
                  Upload
                </Button>
              </div>
            )}

            <InputLabel id="demo-simple-select-label" style={{ marginTop: 20, paddingLeft: 10 }}>Blog Description</InputLabel>
            <input
              key="description"
              type="text"
              id="description"
              className="w-full p-3 border border-gray-300 rounded-md"
              placeholder="Blog Description"
              aria-label="Blog Description"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              autoFocus
            />


            <InputLabel id="demo-simple-select-label" style={{ marginTop: 20, paddingLeft: 10 }}>Blog Tags</InputLabel>
            <input
              key="Blog Tags"
              type="text"
              id="Blog Tags"
              className="w-full p-3 border border-gray-300 rounded-md"
              placeholder="Blog Tags"
              aria-label="Blog Tags"
              name="taglist"
              onChange={(e) => setTaglist(e.target.value)}
              value={taglist}
            />


            <div className="flex justify-between">
              <div style={{ width: '80%' }}>
                <InputLabel id="demo-simple-select-label" style={{ marginTop: 20, paddingLeft: 10 }}>Blog Category</InputLabel>
                <Select
                  margin="normal"
                  className="w-full bg-white border border-gray-300 rounded-md"
                  required
                  name="Blog Category"
                  id="blog_category"
                  multiple
                  value={category}
                  onChange={handleChange1}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {categoryList?.length > 0 && categoryList.map((item) => (
                    <MenuItem key={item.name + item.order} value={item.name}>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>

              </div>

              <div className="flex mt-5 justify-end">
                <Button
                  variant="contained"
                  onClick={() => setOpenModalCategory(true)}
                  style={{ marginTop: 20, marginLeft: 10 }}
                >
                  Add New Category
                </Button>
              </div>
            </div>


            <InputLabel id="demo-simple-select-label" style={{ marginTop: 20 }}>
              Write What's on Your Mind...!
            </InputLabel>
            <JoditEditor
              ref={editor}
              value={post.blog_details}
              onBlur={(data) => contentFieldChanged(data)}
              onPaste={handlePaste}
           
            />
            

            {/* Save Blog Button */}
            {!isPublished &&
              <Button onClick={handleChangeToSaveBlog.bind(null, buttonValue?.save)} variant="contained" style={{ marginTop: 20 }}>
                Save
              </Button>}

            {/* Save And Publish */}
            <Button
              variant="contained"
              onClick={handleChangeToSaveBlog.bind(null, buttonValue?.publish)}
              style={{ marginTop: 20, marginLeft: 10 }}
            >
              {user && user?.position === "super_admin" ?
                "Save & Publish" : "Submit For Review"
              }

            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePreview}
              style={{ marginTop: 20, marginLeft: 10 }}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              onClick={handleChangeToSaveBlog.bind(null, buttonValue?.delete)}
              style={{ marginTop: 20, marginLeft: 10 }}
            >
              Delete
            </Button>

            {/* Show Images Button */}
            {!showImage && (
              <Button
                variant="contained"
                onClick={showUploadedList}
                style={{ marginTop: 20, marginLeft: 10 }}
              >
                Show Images
              </Button>
            )}
            {showImage && (
              <Button
                variant="contained"
                onClick={showUploadedList}
                style={{ marginTop: 20, marginLeft: 10 }}
              >
                Hide Images
              </Button>
            )}

            {showhide && (
              <Button
                variant="contained"
                onClick={showHideUploder}
                style={{ marginTop: 20, marginLeft: 10 }}
              >
                Upload Image
              </Button>
            )}

          </form>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Preview
                </Typography>
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography style={{ margin: 20 }}>
                By <strong>{createdUser}</strong> | <strong>JRA Legal Solution</strong>{" "}
                <br /> {getDate()}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, whiteSpace: "pre-wrap" }}
              >
                <strong>{header}</strong> <br /> <br />
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, whiteSpace: "pre-wrap" }}
              >
                <div dangerouslySetInnerHTML={{ __html: post.blog_details }} />
              </Typography>
            </Box>
          </Modal>
        </div>
        <ToastContainer />

        {/* Add Category Modal */}
        <React.Fragment>
          <Dialog
            open={openModalCategory}
            onClose={() => setOpenModalCategory(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth='xs'
          >
            <DialogTitle id="alert-dialog-title">
              Category Name
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Input type="text" onChange={(e) => setNewCategory(e.target.value)} required className='w-full p-3 border border-gray-300 rounded-md' placeholder='Enter Category Name'></Input>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModalCategory(false)}>Cancel</Button>
              <Button onClick={handleSubmitOfFormModal} autoFocus>
                Add Category
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>

      </Container>
    </ThemeProvider>
  );
}
