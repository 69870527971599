import React, { useState, useEffect } from "react";
import LegalPracticeSection from "./LegalPracticeSection";
import JuristicFeatureSection from "./JuristicFeatureSection";
import MeetOurExpertsSection from "../home/featured/MeetOurExpertsSection";
import PageHeader from "../common/pageHeader/PageHeader";
import {apiService} from "../common/apiService.js";
import Loader from "../common/Loader.jsx"
import dataJson from "../AboutUs.json"

const AboutUs = () => {
  const [pageData, setPageData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // New state for data loading

  //api calling
  const handleSubmit = async () => {
    try {
      const response = await apiService({
        path: "/page/about_us_page",
        method: "GET"
      });
      const responseData = await response.json();
      setPageData(responseData?.data?.page_data);
      setDataLoaded(true); // Set dataLoaded to true after fetching data
    } catch (error) {
      setPageData(dataJson?.page_data);
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      {!dataLoaded && <Loader />}
      {dataLoaded && <PageHeader
        backgroundImageUrl={pageData.backgroundImageUrl}
        title={pageData.page_title}
        description={pageData.page_discription} />}
      {dataLoaded && <JuristicFeatureSection data={pageData?.about_content_info}/>}
      {dataLoaded && <LegalPracticeSection pageData={pageData} />}
      {dataLoaded && <MeetOurExpertsSection teamsData={pageData?.team} basePath={pageData?.base_path}    backgroundImageUrl={pageData.backgroundImageUrl} />}
    </>
  );
};

export default AboutUs;
