import React from "react";

const MapSection = ({ data }) => {
  return (
    <section className="lg:py-8 md:py-5 py-5 px-5 lg:px-10 ">
      <div className="container mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl lg:text-lg text-[#b99c69]">{data?.text_1}</h2>
          <p className="text-4xl mt-2">{data?.text_2}</p>
        </div>
        <div className="lg:w-full xl:w-full ">
          {/* Adjust the width and height directly */}
          <iframe
            src={data?.map_url}
            width="100%"  
            height="500"  
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            title={data?.title}
          ></iframe>
        </div>
      </div>
    </section>

  );
};

export default MapSection;
