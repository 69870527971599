import React, { useState } from "react";
import { SlLike } from "react-icons/sl";
import { FaRegComment } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiService } from "../common/apiService";
import Avatar from "@atlaskit/avatar";
import Comment, { CommentAuthor, CommentTime } from "@atlaskit/comment";

const CommentSection = ({ comments, blogId, callingBlogDetails, likesCount }) => {
  const [showComments, setShowComments] = useState(false);
  const [comment, setComment] = useState({
    name: "",
    blog_id: blogId,
    email: "",
    is_verified: false, 
    is_deleted: false,
    comment_desc: "",
    user_id: null,
  });
  const [likes, setLikes] = useState(likesCount);

  const handleCommentClick = () => {
    setShowComments((prevShowComments) => !prevShowComments);
  };

  const isObjectEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const getDate = (publishDate, createdDate) => {
    const dateString = publishDate || createdDate;
    if (!dateString) return "Unknown date";
    const date = new Date(dateString);
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    return `${date.toLocaleDateString("en-US", dateOptions)}, ${date.toLocaleTimeString("en-US", timeOptions)}`;
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    const details = localStorage.getItem("subscriber_details");
    let data = {};

    if (details && !isObjectEmpty(JSON.parse(details))) {
      data = JSON.parse(details);
      setComment((prevComment) => ({
        ...prevComment,
        email: data.email,
        name: data.name,
      }));
    } else {
      if (!comment.name || comment.name.length < 3) {
        toast("Name is a required field or enter a valid name");
        return;
      }
      if (!comment.email) {
        toast("Email address is a required field");
        return;
      } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(comment.email)) {
          toast("Please enter a valid email address");
          return;
        }
      }
    }

    if (!comment.comment_desc || comment.comment_desc.length < 2) {
      toast("Please write something in the comment box");
      return;
    }

    localStorage.setItem(
      "subscriber_details",
      JSON.stringify({ email: comment.email, name: comment.name })
    );

    try {
      const response = await apiService({
        method: "POST",
        path: "/comment",
        body: comment,
      });

      if (!response.ok) {
        toast("Something went wrong, try later");
        return;
      }

      callingBlogDetails(blogId);
      setComment({
        name: "",
        blog_id: blogId,
        email: "",
        is_verified: false,
        is_deleted: false,
        comment_desc: "",
        user_id: null,
      });
      toast.success("Comment saved successfully");
    } catch (error) {
      toast("Something went wrong, try later");
      console.error("Error:", error);
    }
  };

  const saveLikes = async () => {
    const data = { blog_id: blogId };

    try {
      const response = await apiService({
        method: "POST",
        path: "/like",
        body: data,
      });

      if (!response.ok) {
        toast("Something went wrong, try later");
        return;
      }

      toast("Like saved successfully");
      callingBlogDetails(blogId);
      setLikes((prevLikes) => prevLikes + 1);
    } catch (error) {
      toast("Something went wrong, please try again later");
      console.error("Error:", error);
    }
  };

  const setCommentValues = (e) => {
    const { name, value } = e.target;
    setComment((prevComment) => ({
      ...prevComment,
      [name]: value,
    }));
  };

  return (
    <div className="comment-section mt-4 border-t pt-4 px-4">
      <div className="flex items-center justify-between mb-10 sm:mb-20">
        <div className="flex items-center cursor-pointer" onClick={saveLikes}>
          <SlLike className="mr-2 text-xl sm:text-2xl md:text-3xl" />
          <span>{likes}</span>
        </div>
        <div className="flex items-center cursor-pointer" onClick={handleCommentClick}>
          <FaRegComment className="mr-2 text-xl sm:text-2xl md:text-3xl" />
          <span>{comments.length}</span>
        </div>
      </div>

      <div className="comment-section mb-10 sm:mb-20">
        {showComments &&
          comments.length > 0 &&
          comments.map((comment, index) => (
            <div key={index} className="mb-4">
              <Comment
                avatar={<Avatar name={comment?.name} src={""} />}
                author={<CommentAuthor><p>{comment?.name}</p></CommentAuthor>}
                time={<CommentTime><p className="text-xs">{getDate(comment?.created_date, null)}</p></CommentTime>}
                content={<p className="text-sm sm:text-base ml-2">{comment?.comment_desc}</p>}
              />
            </div>
          ))}
      </div>

      <div className="border-t pt-4">
        <form id="commentform" onSubmit={handleCommentSubmit} className="mt-6 sm:mt-10">
          <h2 className="text-xl sm:text-2xl">Leave a Comment</h2>
          <h6 className="mt-2 text-xs">
            Your email address will not be published. Required fields are marked *
          </h6>
          <div className="mt-6">
            <div className="flex flex-wrap mb-4">
              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <label
                  className="block text-xs font-bold mb-2 text-gray-700"
                  htmlFor="name"
                >
                  Name *
                </label>
                <input
                  className="w-full py-2 px-4 bg-gray-100 text-gray-700 focus:bg-white focus:outline-none focus:ring focus:ring-[#c9b38c] rounded"
                  id="name"
                  name="name"
                  value={comment.name}
                  onChange={setCommentValues}
                  type="text"
                  placeholder="Full Name"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block text-xs font-bold mb-2 text-gray-700"
                  htmlFor="email"
                >
                  Email *
                </label>
                <input
                  className="w-full py-2 px-4 bg-gray-100 text-gray-700 focus:bg-white focus:outline-none focus:ring focus:ring-[#c9b38c] rounded"
                  id="email"
                  name="email"
                  value={comment.email}
                  onChange={setCommentValues}
                  type="email"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-xs font-bold mb-2 text-gray-700"
                htmlFor="comment_desc"
              >
                Comment *
              </label>
              <textarea
                className="w-full py-2 px-4 bg-gray-100 text-gray-700 focus:bg-white focus:outline-none focus:ring focus:ring-[#c9b38c] rounded h-40"
                id="comment_desc"
                name="comment_desc"
                value={comment.comment_desc}
                onChange={setCommentValues}
                placeholder="Write your comment..."
              />
            </div>
            <button
              className="w-full py-3 px-4 bg-[#c9b38c] text-white font-bold rounded hover:bg-[#b49c72] transition"
              type="submit"
            >
              Post Comment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentSection;
